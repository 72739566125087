import React, { useState, useEffect } from "react";

const DashboardCards = ({ data }) => {
  const [currentCounts, setCurrentCounts] = useState([]);

  useEffect(() => {
    if (data.length === 0) return;

    setCurrentCounts(data.map(() => 0)); // Initialize counts to 0

    const intervals = data.map((item, index) => {
      const increment = Math.ceil(item.count / 100); // Adjust for smoother animation
      return setInterval(() => {
        setCurrentCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          if (newCounts[index] < item.count) {
            newCounts[index] = Math.min(newCounts[index] + increment, item.count);
          }
          return newCounts;
        });
      }, 20); // Update every 20ms
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [data]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-4 py-8">
      {data.map((item, index) => (
        <div
          key={index}
          className="p-6 text-center bg-gray-100 shadow-md rounded-lg"
        >
          <h2 className="text-3xl font-bold text-blue-500">
            {currentCounts[index]}
          </h2>
          <p className="text-sm font-medium text-gray-700 mt-2">
            {item.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;
