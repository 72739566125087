import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Navbar from "../Navbar";
import Sidebar from "./superAdminSidebar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCircleInfo } from "react-icons/fa6";

const SuperAdminPeople = () => {
  const [newUser, setNewUser] = useState({
    email: "",
    contact_no: "",
    contact_person: "",
    password: "",
    address: "",
    country: "",
    role: "super_admin"
  });
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  
  const [users, setUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const token = useSelector((state) => state.auth.token);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [affiliationDetails, setAffiliationDetails] = useState(null);

  const fetchAffiliationDetails = async (userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);

    try {
      const response = await handleAPIRequest(
        "post",
        "getADetails",
        formData,
        null,
        token
      );

      if (response && response.affiliations) {
        setAffiliationDetails(response);
        setIsDetailsModalOpen(true);
      } else {
        Swal.fire("Error", "Failed to fetch affiliation details", "error");
      }
    } catch (error) {
      console.error("Error fetching affiliation details:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching affiliation details",
        "error"
      );
    }
  };

  const renderAffiliationDetails = (affiliation) => {
    const { affiliation_type, details } = affiliation;

    switch (affiliation_type) {
      case "App\\Models\\Company":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Company Details</h3>
            <p>
              <strong>Name:</strong> {details.company_name}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Donor":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Donor Details</h3>
            <p>
              <strong>Name:</strong> {details.donor_name}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Supplier":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Supplier Details</h3>
            <p>
              <strong>Name:</strong> {details.supplier_name}
            </p>
            <p>
              <strong>Category:</strong> {details.supplier_category}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Country:</strong> {details.country}
            </p>
          </div>
        );

      default:
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Affiliation Details</h3>
            <pre>{JSON.stringify(details, null, 2)}</pre>
          </div>
        );
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "get-all-users",
        null,
        null,
        token
      );
      if (response && response.status) {
        setUsers(response.users);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to fetch users data",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching users data",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const toggleEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleStatusModal = (user) => {
    setSelectedUser(user);
    setNewStatus(user.status);
    setIsStatusModalOpen(!isStatusModalOpen);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", selectedUser.id);
    formData.append("name", e.target.name.value);
    formData.append("email", e.target.email.value);
    formData.append("contact_number", e.target.contact_no.value);
    const password = e.target.password.value;
    if (password) formData.append("password", password);

    try {
      const response = await handleAPIRequest(
        "post",
        "edit-user-sa",
        formData,
        null,
        token
      );
      if (response && response.status) {
        Swal.fire("Success", "User updated successfully", "success");
        fetchUsers(); // Refresh user list
        setIsEditModalOpen(false); // Close modal
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to update user",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire("Error", "An error occurred while updating user", "error");
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", newUser.email);
    formData.append("contact_no", newUser.contact_no);
    formData.append("contact_person", newUser.contact_person);
    formData.append("password", newUser.password);
    formData.append("address", newUser.address);
    formData.append("country", newUser.country);
    formData.append("role", newUser.role);

    try {
      const response = await handleAPIRequest(
        "post",
        "add-userA",
        formData,
        null,
        token
      );
      if (response) {
        Swal.fire("Success", "User created successfully", "success");
        fetchUsers(); // Refresh user list
        setIsCreateUserModalOpen(false); // Close modal
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to create user",
          "error"
        );
      }
    } catch (error) {
      console.error("Error creating user:", error);
      Swal.fire("Error", "An error occurred while creating user", "error");
    }
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Delete user API logic here
      }
    });
  };

  const handleUpdateStatus = async () => {
    const formData = new FormData();
    formData.append("user_id", selectedUser.id);
    formData.append("status", newStatus);

    try {
      const response = await handleAPIRequest(
        "post",
        "update-account-status",
        formData,
        null,
        token
      );
      if (response && response.status) {
        Swal.fire("Success", "User status updated successfully", "success");
        fetchUsers();
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to update user status",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating user status",
        "error"
      );
    } finally {
      setIsStatusModalOpen(false);
    }
  };

  const filteredUsers = users.filter((user) => {
    const searchString = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchString) ||
      user.email.toLowerCase().includes(searchString) ||
      user.contact_no.toLowerCase().includes(searchString) ||
      user.status.toLowerCase().includes(searchString) ||
      user.roles.some((role) => role.toLowerCase().includes(searchString))
    );
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex flex-col w-full">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">Super Admin People</h2>
          <button
            onClick={() => setIsCreateUserModalOpen(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded mb-2 "
          >
            Add New User
          </button>
          <div className="mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">ID</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Contact No</th>
                  <th className="py-2 px-4 border-b">Role</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr
                    key={user.id}
                    className={
                      user.status === "suspended" ? "bg-yellow-100" : ""
                    }
                  >
                    <td className="py-2 px-4 border-b">{user.id}</td>
                    <td className="py-2 px-4 border-b">{user.name}</td>
                    <td className="py-2 px-4 border-b">{user.email}</td>
                    <td className="py-2 px-4 border-b">{user.contact_no}</td>
                    <td className="py-2 px-4 border-b">
                      {user.roles
                        .map((role) => {
                          // Role mapping logic
                          switch (role) {
                            case "super_admin":
                              return "Super Admin";
                            case "admin":
                              return "Admin";
                              case "editor":
                                return "Editor";
                            case "company_owner":
                              return "NGO";
                            case "company_user":
                              return "NGO Employee";
                            case "donor_owner":
                              return "Donor";
                            case "supplier_owner":
                              return "Supplier";
                            default:
                              return role; // In case of an unknown role
                          }
                        })
                        .join(", ")}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button onClick={() => toggleStatusModal(user)}>
                        {user.status === "active" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                            Active
                          </span>
                        ) : user.status === "suspended" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-black bg-yellow-400">
                            Suspended
                          </span>
                        ) : (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                            Inactive
                          </span>
                        )}
                      </button>
                    </td>
                    <td className="py-2 px-4 border-b flex space-x-2">
                      <button
                        onClick={() => fetchAffiliationDetails(user.id)}
                        className="text-blue-600 hover:text-blue-800 transition duration-200 text-lg"
                      >
                        <FaCircleInfo size={24} />
                      </button>
                      <button
                        onClick={() => toggleEditModal(user)}
                        className="text-blue-600 hover:text-blue-800 transition duration-200 text-lg"
                      >
                        <AiFillEdit size={24} />
                      </button>
                      {/* <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="text-red-600 hover:text-red-800 transition duration-200 text-lg"
                      >
                        <AiFillDelete size={24} />
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>

        {/* Details Modal */}
        {isDetailsModalOpen && affiliationDetails && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/2">
              <h2 className="text-xl font-semibold mb-4">
                Affiliation Details
              </h2>
              {affiliationDetails.affiliations.map((affiliation, index) => (
                <div key={index}>{renderAffiliationDetails(affiliation)}</div>
              ))}
              <div className="flex justify-end">
                <button
                  onClick={() => setIsDetailsModalOpen(false)}
                  className="px-4 py-2 bg-gray-300 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit User Modal */}
        {isEditModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/3">
              <h2 className="text-xl font-semibold mb-4">Edit User</h2>
              <form onSubmit={handleEditUser}>
                <div className="mb-4">
                  <label className="block font-medium">Name:</label>
                  <input
                    type="text"
                    name="name"
                    defaultValue={selectedUser.name}
                    className="border border-gray-300 p-2 w-full rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Email:</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue={selectedUser.email}
                    className="border border-gray-300 p-2 w-full rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Contact Number:</label>
                  <input
                    type="text"
                    name="contact_no"
                    defaultValue={selectedUser.contact_no}
                    className="border border-gray-300 p-2 w-full rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Password:</label>
                  <input
                    type="password"
                    name="password"
                    className="border border-gray-300 p-2 w-full rounded"
                    placeholder="Enter new password if you wish to change"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setIsEditModalOpen(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {isCreateUserModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Create New User</h2>
              <form onSubmit={handleCreateUser}>
                <div className="mb-4">
                  <label htmlFor="email" className="block font-semibold">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser({ ...newUser, email: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="contact_no" className="block font-semibold">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="contact_no"
                    value={newUser.contact_no}
                    onChange={(e) =>
                      setNewUser({ ...newUser, contact_no: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="contact_person"
                    className="block font-semibold"
                  >
                    Contact Person
                  </label>
                  <input
                    type="text"
                    id="contact_person"
                    value={newUser.contact_person}
                    onChange={(e) =>
                      setNewUser({ ...newUser, contact_person: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="block font-semibold">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={newUser.password}
                    onChange={(e) =>
                      setNewUser({ ...newUser, password: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="address" className="block font-semibold">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    value={newUser.address}
                    onChange={(e) =>
                      setNewUser({ ...newUser, address: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="country" className="block font-semibold">
                    Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    value={newUser.country}
                    onChange={(e) =>
                      setNewUser({ ...newUser, country: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="role" className="block font-semibold">
                    Role
                  </label>
                  <select
                    id="role"
                    value={newUser.role}
                    onChange={(e) =>
                      setNewUser({ ...newUser, role: e.target.value })
                    }
                    required
                    className="border border-gray-300 p-2 rounded-md w-full"
                  >
                    <option value="super_admin">Admin</option>
                    <option value="editor">Editor</option>
                  </select>
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => setIsCreateUserModalOpen(false)}
                    className="bg-gray-300 text-black px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    Create User
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Status Modal */}
        {isStatusModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/3">
              <h2 className="text-xl font-semibold mb-4">Update Status</h2>
              <select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                className="border border-gray-300 p-2 w-full rounded mb-4"
              >
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
                <option value="inactive">Inactive</option>
              </select>
              <div className="flex justify-end">
                <button
                  onClick={() => setIsStatusModalOpen(false)}
                  className="px-4 py-2 mr-2 bg-gray-300 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateStatus}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
        <Footer />
      </div>
    </div>
  );
};

export default SuperAdminPeople;
