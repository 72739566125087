import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { toast } from "react-toastify";
import Navbar from "../Navbar";
import Sidebar from "./CompanySidebar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CompanySuppliers = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [file, setFile] = useState(null); // State for file upload
  const [selectedSupplier, setSelectedSupplier] = useState(null); // State to store selected supplier
  const [updatedEmail, setUpdatedEmail] = useState(""); // State to store updated email
  const itemsPerPage = 10;
  const token = useSelector((state) => state.auth.token);
  const { role } = useSelector((state) => state.auth);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [affiliationDetails, setAffiliationDetails] = useState(null);
  const renderAffiliationDetails = (affiliation) => {
    const { affiliation_type, details } = affiliation;

    switch (affiliation_type) {
      case "App\\Models\\Company":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Company Details</h3>
            <p>
              <strong>Name:</strong> {details.company_name}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Donor":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Donor Details</h3>
            <p>
              <strong>Name:</strong> {details.donor_name}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Supplier":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Supplier Details</h3>
            <p>
              <strong>Name:</strong> {details.supplier_name}
            </p>
            <p>
              <strong>Category:</strong> {details.supplier_category}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Country:</strong> {details.country}
            </p>
          </div>
        );

      default:
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Affiliation Details</h3>
            <pre>{JSON.stringify(details, null, 2)}</pre>
          </div>
        );
    }
  };
  const fetchAffiliationDetails = async (userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);

    try {
      const response = await handleAPIRequest(
        "post",
        "getADetails",
        formData,
        null,
        token
      );

      if (response && response.affiliations) {
        setAffiliationDetails(response);
        setIsDetailsModalOpen(true);
      } else {
        Swal.fire("Error", "Failed to fetch affiliation details", "error");
      }
    } catch (error) {
      console.error("Error fetching affiliation details:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching affiliation details",
        "error"
      );
    }
  };
  // Fetch supplier data
  const fetchSuppliers = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "get-suppliers",
        null,
        null,
        token
      );
      if (response && response.status) {
        setSuppliers(response.data);
      } else {
        // Swal.fire(
        //   "Error",
        //   response.message || "Failed to fetch supplier data",
        //   "error"
        // );
      }
    } catch (error) {
      console.error("Error fetching suppliers:", error);
      // Swal.fire(
      //   "Error",
      //   "An error occurred while fetching supplier data",
      //   "error"
      // );
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [token]);

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const filteredSuppliers = suppliers.filter((supplier) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (supplier.contact_person &&
        supplier.contact_person.toLowerCase().includes(searchTermLower)) ||
      (supplier.email &&
        supplier.email.toLowerCase().includes(searchTermLower)) ||
      (supplier.supplier_details?.supplier_category &&
        supplier.supplier_details.supplier_category
          .toLowerCase()
          .includes(searchTermLower))
    );
  });
  const displayedSuppliers = filteredSuppliers.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const pageCount = Math.ceil(filteredSuppliers.length / itemsPerPage);

  // Handle file change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  // Send message with file
  const sendMessage = async () => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("body", messageBody);
    formData.append("email", updatedEmail); // Include the updated email
    if (file) {
      formData.append("file", file); // Append file to form data
    }

    try {
      const response = await handleAPIRequest(
        "post",
        "send-email",
        formData,
        null,
        token,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (response && response.status) {
        toast.success("Message sent successfully!");
        setIsModalOpen(false);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to send message",
          "error"
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
      Swal.fire(
        "Error",
        "An error occurred while sending the message",
        "error"
      );
    }
  };

  // Handle modal open and set selected supplier
  const openModal = (supplier) => {
    setSelectedSupplier(supplier);
    setUpdatedEmail(supplier.email); // Set initial email value
    setIsModalOpen(true);
  };

  return (
    <div className="flex h-screen">
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      <div className="flex flex-col w-full">
        <Navbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">NGO Suppliers</h2>
          <div className="mb-4 flex justify-between items-center">
            {/* Search Input */}
            <input
              type="text"
              placeholder="Search by Name or Email or Supplier Category"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-1/4"
            />
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Contact No</th>
                <th className="py-2 px-4 border-b">Address</th>
                <th className="py-2 px-4 border-b">Country</th>
                <th className="py-2 px-4 border-b">Verified</th>
                <th className="py-2 px-4 border-b">Created At</th>

                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedSuppliers.map((supplier) => (
                <tr key={supplier.id}>
                  <td className="py-2 px-4 border-b">{supplier.id}</td>
                  <td className="py-2 px-4 border-b">
                    {supplier.contact_person}
                  </td>
                  <td className="py-2 px-4 border-b">{supplier.email}</td>
                  <td className="py-2 px-4 border-b">{supplier.contact_no}</td>
                  <td className="py-2 px-4 border-b">{supplier.address}</td>
                  <td className="py-2 px-4 border-b">{supplier.country}</td>
                  <td className="py-2 px-4 border-b">
                    {supplier.status === "active" ? (
                      <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                        Yes
                      </span>
                    ) : (
                      <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                        No
                      </span>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {new Date(supplier.created_at).toLocaleString()}
                  </td>

                  <td className="py-2 px-4 border-b flex space-x-2">
                    <button
                      onClick={() => fetchAffiliationDetails(supplier.id)}
                      className="text-blue-600 hover:text-blue-800 transition duration-200 text-lg"
                    >
                      <FaCircleInfo size={24} />
                    </button>
                    <button onClick={() => openModal(supplier)}>
                      <MdMarkEmailUnread />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {/* Pagination controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-400 text-white px-4 py-2 rounded-md disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {pageCount}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, pageCount))
              }
              disabled={currentPage === pageCount}
              className="bg-gray-400 text-white px-4 py-2 rounded-md disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Details Modal */}
      {isDetailsModalOpen && affiliationDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/2">
            <h2 className="text-xl font-semibold mb-4">Affiliation Details</h2>
            {affiliationDetails.affiliations.map((affiliation, index) => (
              <div key={index}>{renderAffiliationDetails(affiliation)}</div>
            ))}
            <div className="flex justify-end">
              <button
                onClick={() => setIsDetailsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for sending message */}
      {isModalOpen && selectedSupplier && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md w-1/3 max-h-[80vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">Send Email</h3>
            <div className="mb-4">
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="subject"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-md"
              />
            </div>
             <div className="mb-4">
      <label
        className="block text-sm font-medium mb-2"
        htmlFor="message"
      >
        Message
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={messageBody}
        onChange={(event, editor) => {
          const data = editor.getData();
          setMessageBody(data);
        }}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        }}
      />
    </div>

            {/* Email input */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="email">
                Send To
              </label>
              <input
                type="email"
                id="email"
                value={updatedEmail}
                onChange={(e) => setUpdatedEmail(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-md"
              />
            </div>
            {/* File upload */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="file">
                Upload File
              </label>
              <input
                type="file"
                id="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2 rounded-md"
              />
              <p className="text-sm text-red-500 mt-2">
                Kindly do not upload a .docx file.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={sendMessage}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Send
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="ml-2 bg-gray-300 text-black px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default CompanySuppliers;
