import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./logocarasoul.css";
import logo1 from "../../../assets/images/echoLogo.png";
import logo2 from "../../../assets/images/fcdo.png";
import logo3 from "../../../assets/images/usaid.png";
import logo4 from "../../../assets/images/ausaid.png";
import logo5 from "../../../assets/images/msf.png";
import logo6 from "../../../assets/images/naid.jpg";
import { handleAPIRequest } from "../../../api/api";

const LogoCarousel = () => {
  // Hardcoded logos
  const hardcodedLogos = [
    { src: logo1, alt: "ECHO" },
    { src: logo2, alt: "FCDO" },
    { src: logo3, alt: "USAID" },
    { src: logo4, alt: "Australian AID" },
    { src: logo5, alt: "Medicine Sans Frontier" },
    { src: logo6, alt: "Norwegian AID" },
  ];

  const [logos, setLogos] = useState(hardcodedLogos); // Combined logos
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  // Fetch additional logos from API
  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await handleAPIRequest("get", "get-logos", null, null, null);
        if (response && response.status && Array.isArray(response?.logos)) {
          const apiLogos = response?.logos.map((logo) => ({
            src: logo.document_url,
            alt: logo.document_tag || "Logo",
          }));
          // Merge hardcoded logos with API logos
          setLogos([...hardcodedLogos, ...apiLogos]);
        }
      } catch (error) {
        // console.error("Error fetching logos:", error);
      }
    };

    fetchLogos();
  }, []);

  // Adjust itemsPerPage based on screen size
  useEffect(() => {
    const updateItemsPerPage = () => {
      setItemsPerPage(window.innerWidth < 640 ? 1 : 10); // Mobile: 1 logo, Desktop: 10 logos
    };

    updateItemsPerPage(); // Initial setup
    window.addEventListener("resize", updateItemsPerPage);

    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsPerPage >= logos.length ? 0 : prevIndex + itemsPerPage
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsPerPage < 0
        ? logos.length - itemsPerPage
        : prevIndex - itemsPerPage
    );
  };

  const visibleLogos = logos.slice(
    currentIndex,
    currentIndex + itemsPerPage
  ).length
    ? logos.slice(currentIndex, currentIndex + itemsPerPage)
    : logos.slice(0, itemsPerPage); // Wrap around if not enough items

  return (
    <div className="logo-carousel-container py-8 relative max-w-screen-xl mx-auto px-4">
      {/* Left Arrow */}
      <button
        onClick={handlePrev}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-900 focus:outline-none z-10"
      >
        <FaArrowLeft size={30} />
      </button>

      {/* Logo Cards */}
      <div
        className={`flex items-center justify-center ${
          itemsPerPage === 1 ? "space-x-4" : "space-x-6"
        } overflow-hidden`}
      >
        {visibleLogos.map((logo, index) => (
          <div
            key={index}
            className={`${
              itemsPerPage === 1 ? "w-32 h-32" : "w-24 h-24"
            } flex items-center justify-center rounded-full bg-white shadow-md`}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="object-contain w-20 h-20 rounded-full"
            />
          </div>
        ))}
      </div>

      {/* Right Arrow */}
      <button
        onClick={handleNext}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-900 focus:outline-none z-10"
      >
        <FaArrowRight size={30} />
      </button>
    </div>
  );
};

export default LogoCarousel;
