import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import SupplierSidebar from './supplierSidebar';
import Footer from '../Footer';
import DashboardCards from '../dashboardCards/dashboardCards'
import { handleAPIRequest } from '../../../api/api';
const SupplierDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleAPIRequest("get", "count-data", null);

        const data = [
          { title: "Total Tenders Registered", count: response.tt },
          { title: "Total Active Tenders", count: response.at },
          { title: "Total Closed Tenders", count: response.ct },
          { title: "Total NGOs Registered", count: response.nu },
   
          
        ];
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <SupplierSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      {/* Main content */}
      <div
        className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-grow p-6">
          <h2 className="text-2xl font-bold mb-4">Supplier Dashboard</h2>
          <p>Welcome to the Supplier Dashboard! Manage your Supplier details, tenders, and bids here.</p>
          <DashboardCards  data={dashboardData} />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SupplierDashboard;
