import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FaHome,
  FaUser,
  FaUsers,
  FaFileContract,
  FaGavel,
  FaMoneyBill,
  FaHourglassStart,
} from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa6";

const SuperAdminSidebar = ({ isOpen, toggleSidebar }) => {
  const { role } = useSelector((state) => state.auth); // Get role from Redux state
  const adminLinks = [
    { path: "/", label: "Home", icon: <FaHome /> },
    { path: "/dashboard/admin", label: "Dashboard", icon: <FaHourglassStart /> },
    { path: "/dashboard/admin/bank-details", label: "Bank Details", icon: <CiBank /> },
    { path: "/dashboard/admin/blog-details", label: "Blog Details", icon: <FaRegNewspaper /> },
    { path: "/dashboard/admin/profile", label: "Profile", icon: <FaUser /> },
    { path: "/dashboard/admin/people", label: "People", icon: <FaUsers /> },
    { path: "/dashboard/admin/tenders", label: "Tenders", icon: <FaFileContract /> },
    { path: "/dashboard/admin/bidding", label: "Bidding", icon: <FaGavel /> },
    { path: "/dashboard/admin/payments", label: "Payments", icon: <FaMoneyBill /> },
  ];

  return (
    <div
      className={`p-4 fixed lg:static top-0 left-0 w-64 bg-gray-800 text-white transition-transform duration-300 lg:translate-x-0 z-50 ${
        isOpen ? "translate-x-0 min-h-screen" : "-translate-x-full"
      }`}
    >
      {/* Close button for mobile */}
      <button className="lg:hidden text-white mb-4" onClick={toggleSidebar}>
        <MdClose />
      </button>
      <h2 className="text-xl font-bold mb-6">Admin Menu</h2>
      <nav className="space-y-4">
        {adminLinks
          .filter((link) => {
            if (role === "super_admin") {
              return true; // Show all links for super_admin
            } else if (role === "editor") {
              // Only show specific links for editor
              return (
                link.path === "/" || // Home
                link.path === "/dashboard/admin/blog-details" // Blog Details
              );
            }
            return false;
          })
          .map(({ path, label, icon }) => (
            <Link
              key={path}
              to={path}
              className="flex items-center space-x-3 hover:text-blue-400 transition duration-200"
              onClick={toggleSidebar} // Close sidebar on mobile when link is clicked
            >
              {icon}
              <span>{label}</span>
            </Link>
          ))}
      </nav>
    </div>
  );
};

export default SuperAdminSidebar;
