import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/authSlice';
import loginImage from '../../assets/images/login.jpg';
import { handleAPIRequest } from "../../api/api";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../homepage/navbar/Navbar"; // Import Navbar component

const features = [
  "Centralized Tender Listing",
  "Search and Filter Functionality",
  "Subscription Plans for NGOs and Suppliers",
  "Alerts and Notifications",
  "Bid Submission Portal",
  "Vendor and NGO Profiles",
  "Market Rate Calculator",
  "Document Management",
  "Analytics and Insights",
  "Compliance and Safeguarding Section",
];

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignUp = () => navigate('/signup');

  const handleLogin = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
      const response = await handleAPIRequest("post", "login", formData);

      const { Role, token, User, affiliatedDetails } = response;

      dispatch(loginSuccess({
        token,
        role: Role,
        user: User,
        affiliatedDetails,
      }));

      if (Role === 'super_admin' || Role === 'admin') {
        navigate('/dashboard/admin');
      } else if (Role === 'editor'){
        navigate('/dashboard/admin/blog-details');
      } else if (Role === 'company_owner' || Role === 'company_user') {
        navigate(`/dashboard/company`);
      } else if (Role === 'supplier_owner') {
        navigate(`/dashboard/supplier`);
      } else if (Role === 'donor_owner') {
        navigate(`/dashboard/donor`);
      } else {
        toast.error('Unauthorized role');
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center" style={{ backgroundImage: `url(${loginImage})` }}>
      <Navbar /> {/* Add Navbar here */}

      <div className="flex justify-between items-center p-6 w-full h-full">
        {/* Features Section */}
        <div className="w-full md:w-1/3 mb-8 md:mb-0 bg-white bg-opacity-80 p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-center mb-4">Features of NGOs Tenders.com</h2>
          <ul className="space-y-2">
            {features.map((feature, index) => (
              <li key={index} className="text-lg">{`• ${feature}`}</li>
            ))}
          </ul>
        </div>

        {/* Login Form Section */}
        <div className="w-full md:w-1/3 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-center mb-6">Login</h1>

          <div className="mb-4">
            <input
              type="email"
              className="w-full p-3 border border-gray-300 rounded-md"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-6">
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                className="w-full p-3 border border-gray-300 rounded-md"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={handleClickShowPassword}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <button
            onClick={handleLogin}
            className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Login
          </button>

          <div className="flex justify-between mt-4">
            <button className="text-sm text-blue-500">Forgot Password?</button>
            <button
              onClick={handleSignUp}
              className="text-sm text-blue-500"
            >
              Sign Up
            </button>
          </div>

          <p className="mt-4 text-center text-sm">
            <Link to="/" className="text-blue-500 hover:underline">Go back to Home</Link>
          </p>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;
