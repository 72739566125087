import React from 'react';

const PriceButton = () => {
  return (
    <div>
      <hr className="my-6" />
      <p className="text-lg font-semibold text-gray-600 mb-4">
        You can view prices for various items below:
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <a
          href="https://www.mycart.pk"
          target="_blank"
          rel="noopener noreferrer"
          className="block p-6 text-center bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg shadow-lg transition duration-200"
        >
          Food Items
        </a>
        <a
          href="https://priceinfo.pk"
          target="_blank"
          rel="noopener noreferrer"
          className="block p-6 text-center bg-green-500 hover:bg-green-600 text-white font-bold rounded-lg shadow-lg transition duration-200"
        >
          Construction Material
        </a>
        <a
          href="https://daraz.pk"
          target="_blank"
          rel="noopener noreferrer"
          className="block p-6 text-center bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-lg shadow-lg transition duration-200"
        >
          Other Items
        </a>
      </div>
    </div>
  );
};

export default PriceButton;
