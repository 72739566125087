import React, { useState, useEffect } from "react";
import Navbar from "../Navbar"; // Adjust the import path
import Sidebar from "./superAdminSidebar"; // Adjust the import path
import Footer from "../Footer"; // Adjust the import path
import { handleAPIRequest } from "../../../api/api"; // Adjust the import path
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify"; // Ensure you have react-toastify installed and imported
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications

const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await handleAPIRequest(
          "get",
          "show-blogs",
          null,
          null,
          token
        );
        setBlogDetails(response.data || []);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        toast.error("Failed to fetch blog details.");
      }
    };

    fetchBlogDetails();
  }, [token]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleShowModal = (data = null) => {
    setCurrentData(data);
    setIsEdit(!!data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentData(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("blog_title", currentData?.blog_title);
    formData.append("blog_description", currentData?.blog_description);
    formData.append("meta_description", currentData?.meta_description);
    if (currentData?.image || !isEdit) {
        formData.append("blog_image", currentData?.image || null);
      }

    try {
      const response = await handleAPIRequest(
        "post",
        isEdit ? `update-blog/${currentData.id}` : "add-blog",
        formData,
        null,
        token
      );
      toast.success(
        isEdit ? "Blog updated successfully!" : "Blog added successfully!"
      );
      if (isEdit) {
        setBlogDetails((prev) =>
          Array.isArray(prev)
            ? prev.map((item) =>
                item.id === currentData.id ? response.data : item
              )
            : [response.data]
        );
      } else {
        setBlogDetails((prev) =>
          Array.isArray(prev) ? [...prev, response.data] : [response.data]
        );
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error saving blog details:", error);
      toast.error("Failed to save blog details.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await handleAPIRequest("delete", `delete-blog/${id}`, null, null, token);
      toast.success("Blog deleted successfully!");
      setBlogDetails((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Failed to delete blog.");
    }
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-grow p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-700">Blog Details</h2>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={() => handleShowModal()}
            >
              Add Blog Detail
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Blog Title
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Description
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Blog Image
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Created At
                  </th>
                  <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogDetails.length > 0 ? (
                  blogDetails.map((detail) => (
                    <tr key={detail.id} className="border-b">
                      <td className="py-3 px-6 text-gray-600">{detail.blog_title}</td>
                      <td className="py-3 px-6 text-gray-600">
                        {detail.blog_description.slice(0, 30)}...
                      </td>
                      <td className="py-3 px-6 text-gray-600">
                        <button
                          className="text-blue-500"
                          onClick={() => window.open(detail.blog_image, "_blank")}
                        >
                          View Image
                        </button>
                      </td>
                      <td className="py-3 px-6 text-gray-600">{new Date(detail.created_at).toLocaleDateString()}</td>
                      <td className="py-3 px-6 text-center">
                        <button
                          className="bg-yellow-500 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-600"
                          onClick={() => handleShowModal(detail)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                          onClick={() => handleDelete(detail.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="py-3 px-6 text-center text-gray-500">
                      No blog details added.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
        <ToastContainer />
        <Footer />
      </div>
      {showModal && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white w-11/12 max-w-lg p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold mb-4">
        {isEdit ? "Edit Blog Detail" : "Add Blog Detail"}
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Blog Title
          </label>
          <input
            type="text"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={currentData?.blog_title || ""}
            onChange={(e) =>
              setCurrentData({
                ...currentData,
                blog_title: e.target.value,
              })
            }
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Blog Description
          </label>
          <textarea
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={currentData?.blog_description || ""}
            onChange={(e) =>
              setCurrentData({
                ...currentData,
                blog_description: e.target.value,
              })
            }
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Meta Description
          </label>
          <textarea
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={currentData?.meta_description || ""}
            onChange={(e) =>
              setCurrentData({
                ...currentData,
                meta_description: e.target.value,
              })
            }
          />
        </div>
        
        {/* Display the current image (if any) */}
        {isEdit && currentData?.blog_image && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Current Image</label>
            <div className="mt-2">
              <img
                src={currentData.blog_image}
                alt="Blog Preview"
                className="w-32 h-32 object-cover rounded-md border"
              />
            </div>
          </div>
        )}
        
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Blog Image
          </label>
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={(e) =>
              setCurrentData({
                ...currentData,
                image: e.target.files[0],
              })
            }
            className="mt-1 block w-full"
          />
        </div>
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          onClick={handleCloseModal}
        >
          Close
        </button>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default BlogDetails;
