import React, { useState, useEffect } from "react";
import Navbar from "../Navbar"; // Import your Navbar component
import Footer from "../Footer"; // Import your Footer component
import { handleAPIRequest } from "../../../api/api"; // Make sure to import your API handling function
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai"; // Import icons from react-icons
import Swal from "sweetalert2"; // Import SweetAlert
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DonorSidebar from "./donorSidebar";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

const DonorTender = () => {
  const navigate = useNavigate();
  const handleBidsClick = (tenderTitle) => {
    navigate("/dashboard/donor/bidding", { state: { tenderTitle } });
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [isOpen, setIsOpen] = useState(false); // For Add Tender modal
  const [isEditOpen, setIsEditOpen] = useState(false); // For Edit Tender modal
  const [tenders, setTenders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [bidMethodModal, setBidMethodModal] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [tendersPerPage] = useState(10); // Set items per page
  const [newTender, setNewTender] = useState({
    tender_title: "",
    tender_description: "",
    tender_category: "",
    item_category: "",
    tender_start_date: "",
    tender_end_date: "",
    tender_pack_url: "",
    tender_document: null,
    status: "open",
    featured: false, // Added featured property
  });
  const [selectedTender, setSelectedTender] = useState(null); // State for the selected tender
  const [selectedDocument, setSelectedDocument] = useState(null); // State for the selected document
  const token = useSelector((state) => state.auth.token); // Get the token from Redux state
  // const handleBidMethod = (method) => {
  //   // Placeholder for API call
  // };

  // State for the modal
  const [featuredModal, setFeaturedModal] = useState(false);
  const [featuredStatus, setFeaturedStatus] = useState("");

  // Function to open the featured status modal
  const openFeaturedModal = (tender) => {
    setSelectedTender(tender);
    setFeaturedStatus(tender.featured);
    setFeaturedModal(true);
  };

  // Function to handle the featured status update
  const handleFeaturedUpdate = async () => {
    try {
      const formData = {
        type: featuredStatus, // 'yes' or 'no'
        tenderId: selectedTender?.id,
      };

      const response = await handleAPIRequest(
        "post",
        "update-feature-status",
        formData,
        null,
        token
      );

      if (response?.message === "Tender featured status updated successfully") {
        toast.success("Featured status updated successfully");
        // Update the tender list or refresh data if necessary
      } else {
        toast.error("Failed to update featured status");
      }
    } catch (error) {
      toast.error("Error updating featured status:", error);
    } finally {
      setFeaturedModal(false);
    }
  };
  const toggleDocument = (document) => {
    setRequiredDocuments((prev) =>
      prev.includes(document)
        ? prev.filter((doc) => doc !== document)
        : [...prev, document]
    );
  };
  const handleDocumentDownload = (documentUrl) => {
    // Create an anchor tag to trigger the download
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = documentUrl.split("/").pop(); // Extract the file name for download
    link.click();
  };

  const toggleDocumentModal = () => setSelectedDocument(null); // Close document modal

  // Fetch company tenders on component mount
  useEffect(() => {
    const fetchTenders = async () => {
      try {
        const response = await handleAPIRequest(
          "get",
          "get-donor-tenders",
          null,
          null,
          token
        );
        if (response) {
          setTenders(response.tenders); // Update state with fetched tenders
        }
      } catch (error) {
        console.error("Error fetching tenders:", error);
      }
    };

    fetchTenders();
  }, [token]);

  // Search functionality
  const filteredTenders = tenders.filter(
    (tender) =>
      tender.tender_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tender.tender_description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastTender = currentPage * tendersPerPage;
  const indexOfFirstTender = indexOfLastTender - tendersPerPage;
  const currentTenders = filteredTenders.slice(
    indexOfFirstTender,
    indexOfLastTender
  );
  const totalPages = Math.ceil(filteredTenders.length / tendersPerPage);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-1">
        <DonorSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="flex-1 flex flex-col">
          <Navbar toggleSidebar={toggleSidebar} />

          <div className="p-8 flex-1">
            <div className="mb-8">
              <h1 className="text-3xl font-bold mb-2">Company Tenders</h1>
              <p className="text-gray-600">
                Manage all company tenders here. You can view, add, edit, or
                delete tenders.
              </p>
            </div>

            <div className="flex justify-between mb-6">
              <input
                type="text"
                placeholder="Search Tenders..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full max-w-md"
              />
              {/* <button
                onClick={toggleModal}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200 ml-4"
              >
                Add Tender
              </button> */}
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="py-2 px-4 border">Tender ID</th>
                    <th className="py-2 px-4 border">Title</th>
                    <th className="py-2 px-4 border">Description</th>
                    <th className="py-2 px-4 border">Item Category</th>
                    <th className="py-2 px-4 border">Tender Category</th>
                    <th className="py-2 px-4 border">Start Date</th>
                    <th className="py-2 px-4 border">End Date</th>
                    <th className="py-2 px-4 border">Document</th>
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Featured</th>
                    <th className="py-2 px-4 border">Bid Method</th>
                    <th className="py-2 px-4 border">Bids</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTenders.map((tender) => (
                    <tr key={tender.id} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border">{tender.id}</td>
                      <td className="py-2 px-4 border">
                        {tender.tender_title}
                      </td>
                      <td className="py-2 px-4 border">
                        {tender.tender_description.length > 100 ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                `${tender.tender_description.slice(0, 100)}...`
                              ),
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                tender.tender_description
                              ),
                            }}
                          />
                        )}
                      </td>
                      <td className="py-2 px-4 border">
                        {tender.item_category}
                      </td>
                      <td className="py-2 px-4 border">
                        {tender.tender_category}
                      </td>
                      <td className="py-2 px-4 border">
                        {new Date(
                          tender.tender_start_date
                        ).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4 border">
                        {new Date(tender.tender_end_date).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4 border">
                        <button
                          onClick={() =>
                            handleDocumentDownload(
                              tender.documents.document_url
                            )
                          }
                          className="text-blue-500 hover:underline"
                        >
                          View Document
                        </button>
                      </td>
                      <td className="py-2 px-4 border">{tender.status}</td>
                      <td className="py-2 px-4 border cursor-pointer">
                        {tender.featured === "yes" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                            Yes
                          </span>
                        ) : (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                            No
                          </span>
                        )}
                      </td>
                      <td className="py-2 px-4 border">{tender.bid_method}</td>
                      <td className="py-2 px-4 border">
                        {tender.bids_count > 0 ? (
                          <span
                            onClick={() => handleBidsClick(tender.tender_title)}
                            className="text-blue-500 hover:underline cursor-pointer"
                          >
                            {tender.bids_count}
                          </span>
                        ) : (
                          tender.bids_count
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-4 flex justify-between items-center">
              <div>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </div>
              <div>
                <button
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
                >
                  Previous
                </button>
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200 ml-2"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {/* Document Modal */}
      {selectedDocument && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Tender Document</h2>
            <iframe
              src={selectedDocument}
              title="Tender Document"
              className="w-full h-96"
            />
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={toggleDocumentModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default DonorTender;
