import React, { useState, useEffect } from "react";
import './priceStyle.css';
import { useSelector } from "react-redux";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import waimg from "../../../assets/images/whatsapp.png";
import { handleAPIRequest } from "../../../api/api";

const Pricing = () => {
  const [focusedCard, setFocusedCard] = useState(null);
  const [bankDetails,setBankDetails] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const pricingPlans = [
    {
      role: "Supplier",
      price: "1 Month 1000PKR | 6 Months  5000PKR |  1 Year 8000PKR",
      features: [
        "Feature 1: View all details of a tender ",
        "Feature 2: Bid for Unlimited Tenders",
        "Feature 3: 24/7 support",
        "Feature 4: Archive Applied Tenders",
      ],
      color: "bg-gradient-to-r from-green-400 to-blue-500", // Gradient background
    },
    {
      role: "NGO",
      price: "1 Month 150 Euro | 3 Month 300 Euro | 6 Month 500 Euro | 800 Euro Annual ",
      features: [
        "Feature 1: Create/Customize Tenders",
        "Feature 2: Manage Company Employee Accounts",
        "Feature 3: Update Bidding Rules",
        "Feature 4: Update Profiles",
      ],
      color: "bg-gradient-to-r from-purple-400 to-pink-600", // Gradient background
    },
    {
      role: "Donor",
      price: "1500 Euro Annual",
      features: [
        "Feature 1: Ability to view project status",
        "Feature 2: Access to NGO needs",
        "Feature 3: Direct donation to specific causes",
        "Feature 4: Tracking your donations",
      ],
      color: "bg-gradient-to-r from-yellow-400 to-red-500", // Gradient background
    },
  ];


  const fetchBanks = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        `get-bank-detailsA`,
        null,
        null,
        token
      );
      if (response?.data) {
        setBankDetails(response.data);
      } else {
        console.log("Failed to fetch bank details.");
      }
    } catch (error) {
      console.error("An error occurred while fetching bank details.");
    } 
  };

  useEffect(() => {
      fetchBanks();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />

      <div className="text-center mb-8 mt-10">
        <p className="text-3xl font-bold mb-4">Choose the Right Plan for You</p>
        <p className="text-lg text-gray-700 mb-6">
          Select the role that best suits your needs. Whether you're a Supplier, NGO, or Donor, we have the perfect tools and features for you.
        </p>
      </div>

      {/* Pricing Plans */}
      <div className="flex justify-center space-x-6 flex-wrap mb-10">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`p-10 rounded-xl shadow-xl w-full max-w-sm cursor-pointer transition-all duration-300 ease-in-out transform ${
              focusedCard === index ? "scale-105 border-4 border-yellow-300" : "scale-100"
            } ${plan.color} mb-6`}
            onClick={() => setFocusedCard(index)}
          >
            <h3 className="text-3xl font-bold text-white text-center mb-4">{plan.role}</h3>
            <p className="text-xl font-semibold text-white text-center mb-4">{plan.price}</p>
            <ul className="list-disc pl-5 text-white">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="mb-3">{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Bank Details Table */}
      <div className="flex justify-center mb-10 w-full">
        <div className="p-8 rounded-xl shadow-xl w-full bg-gradient-to-r from-blue-500 to-teal-600 mb-6">
          <h3 className="text-3xl font-bold text-white text-center mb-4">Bank Details</h3>
          
          <div className="overflow-x-auto w-full">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Bank Name</th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Account Title</th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Account Number</th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">IBAN</th>
                </tr>
              </thead>
              <tbody>
                {bankDetails.length > 0 ? (
                  bankDetails.map((detail, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-3 px-6 text-gray-600">{detail.bank_name}</td>
                      <td className="py-3 px-6 text-gray-600">{detail.account_title}</td>
                      <td className="py-3 px-6 text-gray-600">{detail.account_number}</td>
                      <td className="py-3 px-6 text-gray-600">{detail.iban}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="py-3 px-6 text-center text-gray-500">
                      No bank details available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      {/* WhatsApp Fixed Floating Button */}
      <a
        href="https://wa.me/+923121111002"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>
    </div>
  );
};

export default Pricing;
