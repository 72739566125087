import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBars, FaTimes, FaBell, FaCaretDown } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa6";
import navbarLogo from "../../../assets/images/navbarLogo.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/authSlice";
import { persistor } from "../../../redux/store";
import { handleAPIRequest } from "../../../api/api";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { isAuthenticated, user, role, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await handleAPIRequest("get", "get-notificatons", null, null, token);
        if (response?.notifications) {
          setNotifications(response.notifications);
          setUnreadCount(response.notifications.length); // You can update this logic as per your need
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    if (isAuthenticated) {
      fetchNotifications();
    }
  }, [isAuthenticated, token]);

  const handleDashboardRoute = () => {
    if (role === "super_admin" || role === "admin") {
      navigate("/dashboard/admin");
    } else if (role === "editor") {
      navigate(`/dashboard/admin/blog-details`);
    } else if (role === "company_owner" || role === "company_user") {
      navigate(`/dashboard/company`);
    } else if (role === "supplier_owner") {
      navigate(`/dashboard/supplier`);
    } else if (role === "donor_owner") {
      navigate(`/dashboard/donor`);
    } else {
      toast.error("Unauthorized role");
    }
  };

  const handleLogout = async () => {
    try {
      dispatch(logout());
      persistor.purge();
      navigate("/login");
      toast.success("Successfully logged out");
    } catch (error) {
      toast.error("An error occurred during logout");
    }
  };

  return (
    <nav className="bg-gray-900 p-4 shadow-md">
      <div className="container mx-auto flex items-center">
        {/* Logo and Heading */}
        <div className="flex items-center">
          <img
            src={navbarLogo}
            alt="Logo"
            className="rounded-full mr-2"
            style={{ height: "48px", width: "72px" }}
          />
          <h1 className="text-xl font-bold text-white">WELCOME TO NGO TENDERS</h1>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMobileMenu}
          className="ml-auto bg-green-500 text-white p-2 rounded-md md:hidden"
        >
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6 items-center ml-auto">
          <Link to="/" className="text-white hover:text-green-500">Home</Link>
          <Link to="/guide" className="text-white hover:text-green-500">Guide</Link>
          <Link to="/pricing" className="text-white hover:text-green-500">Price Plans</Link>
          <Link to="/blogs" className="text-white hover:text-green-500">Blogs</Link>
          <Link to="/about-us" className="text-white hover:text-green-500">About Us</Link>

          {isAuthenticated && (
            <>
              <Link to="https://itemscatalogue.redcross.int/" className="block text-white py-2">
                Item Specifications
              </Link>
              <div className="relative">
                <button
                  onClick={toggleNotificationDropdown}
                  className="text-white hover:text-green-500 relative"
                >
                  <FaBell size={24} />
                  {/* Show unread count as a badge */}
                  {unreadCount > 0 && (
                    <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1">
                      {unreadCount}
                    </span>
                  )}
                </button>
                {isNotificationOpen && (
                  <div className="absolute right-0 mt-2 w-64 bg-white text-black shadow-lg rounded-md p-4 z-50 max-h-64 overflow-y-auto">
                    <h4 className="font-bold mb-2">Notifications</h4>
                    <ul className="space-y-2">
                      {notifications.map((notification) => (
                        <li key={notification.id} className="flex justify-between items-center border-b pb-2">
                          <span>{notification.message}</span>
                          <span className="text-green-500 text-sm">✔</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Add other user dropdown logic */}
              <div className="relative">
                <button onClick={toggleDropdown} className="text-white hover:text-green-500">
                  {user?.contact_person || "User"}
                  <FaCaretDown className="ml-1" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white text-black shadow-lg rounded-md p-2 z-50">
                    <button
                      onClick={handleDashboardRoute}
                      className="block w-full text-left px-4 py-2 hover:bg-green-500 hover:text-white rounded-md mb-2"
                    >
                      Dashboard
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 hover:bg-green-500 hover:text-white rounded-md"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {!isAuthenticated && (
            <>
              <Link to="/login" className="text-white hover:text-green-500">Login</Link>
              <Link to="/signup" className="text-white hover:text-green-500">Signup</Link>
            </>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-blue-600 p-4 space-y-2">
          <Link to="/" className="block text-white py-2">Home</Link>
          <Link to="/pricing" className="block text-white py-2">Price Plans</Link>
          <Link to="/blogs" className="block text-white py-2">Blogs</Link>
          <Link to="/about-us" className="block text-white py-2">About Us</Link>

          {isAuthenticated && (
            <>
              <Link to="https://itemscatalogue.redcross.int/" className="block text-white py-2">
                Item Specifications
              </Link>
              <div className="relative">
                <button
                  onClick={toggleNotificationDropdown}
                  className="block w-full text-white py-2 text-left"
                >
                  Notifications
                  <FaBell className="inline ml-2" size={20} />
                </button>
                {isNotificationOpen && (
                  <div className="mt-2 w-full bg-white text-black shadow-lg rounded-md p-4 z-50 max-h-64 overflow-y-auto">
                    <h4 className="font-bold mb-2">Notifications</h4>
                    <ul className="space-y-2">
                      {notifications.map((notification) => (
                        <li key={notification.id} className="flex justify-between items-center border-b pb-2">
                          <span>{notification.message}</span>
                          <span className="text-green-500 text-sm">✔</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <button
                onClick={handleDashboardRoute}
                className="block w-full text-left text-white py-2"
              >
                Dashboard
              </button>
              <button
                onClick={handleLogout}
                className="block w-full text-left text-white py-2"
              >
                Logout
              </button>
            </>
          )}

          {!isAuthenticated && (
            <>
              <Link to="/login" className="block text-white py-2">Login</Link>
              <Link to="/signup" className="block text-white py-2">Signup</Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
