import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import BidModal from "./BidModal";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import waimg from "../../assets/images/whatsapp.png";
import DOMPurify from "dompurify";

const SingleTender = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isBidModalOpen, setBidModalOpen] = useState(false);
  const { tender } = location.state || {};
  const userRole = useSelector((state) => state.auth.role);

  if (!tender) {
    navigate("/", { replace: true });
    return null;
  }

  const {
    tender_title,
    tender_description,
    tender_start_date,
    tender_end_date,
    tender_category,
    item_category,
    companies,
    documents,
    bid_method,
  } = tender;

  const openBidModal = () => setBidModalOpen(true);
  const closeBidModal = () => setBidModalOpen(false);

  const isSupplierOwner = userRole === "supplier_owner";
  const now = dayjs(); // Get current date and time
  const isBiddingOpen = now.isBefore(dayjs(tender_end_date)); // Check if current date is before tender_end_date

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <main className="flex-grow p-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          {tender_title}
        </h1>

        {/* Company Information */}
        <div className="mb-6 p-6 border rounded-lg shadow-md bg-white">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Organization Information
          </h2>
          <div className="flex flex-wrap gap-4">
            <span className="text-sm text-white bg-blue-500 px-4 py-2 rounded-md inline-block">
              Organization Name: {companies?.company_name}
            </span>
            <span className="text-sm text-white bg-blue-500 px-4 py-2 rounded-md inline-block">
              Registration No: {companies?.registration_no}
            </span>
            <span className="text-sm text-white bg-blue-500 px-4 py-2 rounded-md inline-block">
              Address: {companies?.address}
            </span>
            <span className="text-sm text-white bg-blue-500 px-4 py-2 rounded-md inline-block">
              Website:{" "}
              <a
                href={`https://${companies?.website}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                {companies?.website}
              </a>
            </span>
          </div>
        </div>

        {/* Tender Details */}
        <div className="mb-6 p-6 border rounded-lg shadow-md bg-white">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Tender Details
          </h2>
          <div className="flex flex-wrap gap-4">
            <span className="text-sm text-white bg-green-500 px-4 py-2 rounded-md inline-block">
              Start Date: {dayjs(tender_start_date).format("DD MMMM YYYY")}
            </span>
            <span className="text-sm text-white bg-green-500 px-4 py-2 rounded-md inline-block">
              End Date: {dayjs(tender_end_date).format("DD MMMM YYYY")}
            </span>
            <span className="text-sm text-white bg-purple-500 px-4 py-2 rounded-md inline-block">
              Tender Category: {tender_category}
            </span>
            <span className="text-sm text-white bg-purple-500 px-4 py-2 rounded-md inline-block">
              Item Category: {item_category}
            </span>
            <span
              className={`text-sm px-4 py-2 rounded-md ${
                bid_method === "Online"
                  ? "bg-green-500 text-white"
                  : "bg-red-500 text-white"
              }`}
            >
              {bid_method === "Online" ? "Online Bid" : "Manual Bid"}
            </span>
          </div>

          <p
            className="mt-4 text-gray-700 "
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
               tender.tender_description
              ),
            }}
          />
        </div>

        {/* Download Document and Bid Section */}
        <div className="flex space-x-4 items-center">
          {documents?.document_url ? (
            <a
              href={documents.document_url}
              download
              className="bg-green-600 text-white py-2 px-6 rounded-md hover:bg-green-700"
            >
              View Tender Document
            </a>
          ) : (
            <p className="text-red-500">No document available for download.</p>
          )}

          {isSupplierOwner && (
            <>
              {isBiddingOpen ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openBidModal}
                  className="ml-4"
                >
                  Bid Now
                </Button>
              ) : (
                <p className="text-red-500 font-semibold ml-4">
                  Bidding is closed. You can't bid now.
                </p>
              )}
            </>
          )}
        </div>

        {/* Bid Modal */}
        <BidModal
          open={isBidModalOpen}
          onClose={closeBidModal}
          tender={tender}
        />
      </main>
      <Footer />
      {/* WhatsApp Fixed Floating Button */}
      <a
        href="https://wa.me/+923121111002"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>
    </div>
  );
};

export default SingleTender;
