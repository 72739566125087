import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { persistor } from '../../redux/store';
import { handleAPIRequest } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import { FaSignOutAlt } from "react-icons/fa"; // Importing the logout icon
import "react-toastify/dist/ReactToastify.css";

const Navbar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const handleLogout = async () => {
    try {
      const response = await handleAPIRequest("post", "logout", null, null, token);
      if (response) {
        dispatch(logout());
        persistor.purge();
        navigate("/login");
        toast.success("Successfully logged out");
      } else {
        toast.error("Failed to log out");
      }
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("An error occurred during logout");
    }
  };

  return (
    <nav className="bg-gray-800 text-white p-4 flex items-center relative">
      {/* Left: Hamburger Button (visible in mobile view) */}
      <button
        onClick={toggleSidebar}
        className="absolute left-4 lg:hidden text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      {/* Center: Dashboard Heading */}
      <h1 className="text-xl font-bold mx-auto">Dashboard</h1>

      {/* Right: Logout Icon */}
      <button
        onClick={handleLogout}
        className="absolute right-4 text-red-500 hover:text-red-600 transition duration-200"
        title="Logout"
      >
        <FaSignOutAlt className="h-6 w-6" />
      </button>
    </nav>
  );
};

export default Navbar;
