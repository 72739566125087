import React, { useState, useEffect } from "react";
import { handleAPIRequest } from "../../../api/api";

const CounterCards = () => {
  const [counters, setCounters] = useState([]);
  const [currentCounts, setCurrentCounts] = useState([]);

  useEffect(() => {
    // Fetch data using handleAPIRequest
    const fetchCounters = async () => {
      try {
        const response = await handleAPIRequest("get", "count-data", null);

        const data = [
          { title: "Total Active Tenders", count: response.at },
          { title: "Total Suppliers Registered", count: response.su },
          { title: "Total NGOs Registered", count: response.nu },
          { title: "Total Closed Tenders", count: response.ct },
        ];
        setCounters(data);
        setCurrentCounts(data.map(() => 0)); // Initialize counts to 0
      } catch (error) {
        console.error("Error fetching counters:", error);
      }
    };

    fetchCounters();
  }, []);

  useEffect(() => {
    if (counters.length === 0) return;

    const intervals = counters.map((counter, index) => {
      const increment = Math.ceil(counter.count / 100); // Adjust for smoother animation
      return setInterval(() => {
        setCurrentCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          if (newCounts[index] < counter.count) {
            newCounts[index] = Math.min(newCounts[index] + increment, counter.count);
          }
          return newCounts;
        });
      }, 20); // Update every 20ms
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [counters]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-4 py-8">
      {counters.map((counter, index) => (
        <div
          key={index}
          className="p-6 text-center bg-gray-100 shadow-md rounded-lg"
        >
          <h2 className="text-3xl font-bold text-blue-500">
            {currentCounts[index]}
          </h2>
          <p className="text-sm font-medium text-gray-700 mt-2">
            {counter.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CounterCards;
