import React from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { motion } from "framer-motion";
import waimg from "../../assets/images/whatsapp.png";
import blogo from "../../assets/images/bilcono.jpg";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />

      <main className="flex-grow flex flex-col items-center px-6 py-10">
        {/* Header */}
        <img
          src={blogo}
          alt="Bilcono Logo"
          className="w-40 h-auto mb-4 shadow-md rounded-lg transform transition duration-300 hover:scale-110"
        />
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 md:text-5xl">
          About Bilcono – A Complete Solution for NGO Procurement
        </h1>

        {/* Introduction */}
        <motion.p
          className="text-lg text-center max-w-3xl mb-6 text-gray-600 md:text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          NGO Tenders – Streamlined Procurement for NGOs, Suppliers, and Donors
          Bilcono provides a platform that helps NGOs connect with verified
          suppliers and manage procurement easily. Our system offers real-time
          tracking, ensuring transparency, efficiency, and accountability. By
          bringing together active tenders and NGO tenders, we make it simple
          for organizations to find the right suppliers and for donors to
          follow the progress of projects. Start managing your procurement
          needs with ease—sign up with Bilcono now!
        </motion.p>

        {/* Section: Who We Are */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Who We Are
            </h2>
            <p className="text-lg text-center text-gray-600">
              At Bilcono, we’re reshaping the development sector with innovative
              digital tools to simplify procurement. Our platform brings
              together NGO tenders and active tenders in one place, making it
              easier for NGOs, suppliers, and donors to collaborate effectively.
            </p>
          </div>
        </motion.div>

        {/* Section: Our Mission */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Our Mission
            </h2>
            <p className="text-lg text-center text-gray-600">
              Our mission is simple yet impactful: to simplify and enhance the
              procurement process for NGOs, suppliers, and donors in Pakistan
              and beyond.
            </p>
          </div>
        </motion.div>

        {/* Section: A Complete Solution */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              A Complete Solution for All Stakeholders
            </h2>
            <div className="text-lg text-gray-600 space-y-4">
              <p>
                <strong>For Suppliers:</strong> Access active tenders from all
                NGOs on a single, user-friendly platform.
              </p>
              <p>
                <strong>For NGOs:</strong> Discover and connect with a diverse
                network of verified suppliers.
              </p>
              <p>
                <strong>For Donors:</strong> Monitor the entire procurement
                process in real-time with full transparency.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Section: Key Features */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Key Features of the NGO Tenders Portal
            </h2>
            <ul className="text-lg list-disc list-inside text-gray-600 space-y-3">
              <li>Tender Consolidation</li>
              <li>Supplier Directory</li>
              <li>Donor Monitoring</li>
              <li>Market Rates</li>
              <li>IFRC Specifications</li>
              <li>Transparent Processes</li>
            </ul>
          </div>
        </motion.div>

        {/* Section: Contact Information */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Contact Us
            </h2>
            <div className="text-lg text-gray-600 space-y-2">
              <p>
                <strong>For Sales:</strong> sales@ngostenders.com
              </p>
              <p>
                <strong>For Support:</strong> support@ngostenders.com
              </p>
              <p>
                <strong>24/7 Support:</strong> +92 312 1111 002
              </p>
            </div>
          </div>
        </motion.div>
      </main>

      <Footer />

      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/+923121111002"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-500 rounded-full p-3 shadow-lg transform hover:scale-105 transition-transform"
      >
        <img src={waimg} alt="Chat with us" className="w-8 h-8" />
      </a>
    </div>
  );
};

export default AboutUs;
