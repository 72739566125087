import React, {useState , useEffect} from 'react';
import Navbar from '../Navbar'; // Adjust the import path if necessary
import CompanySidebar from './CompanySidebar';
import Footer from '../Footer'; // Adjust the import path if necessary
import DashboardCards from '../dashboardCards/dashboardCards';
import { handleAPIRequest } from '../../../api/api';
import PriceButton from '../priceButtons';
import { useSelector } from "react-redux";
const CompanyDashboard = () => {
  // Dummy data for the graph
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [dashboardData, setDashboardData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleAPIRequest("get", "count-dataN",null,null,token);
        const data = [
          { title: "Total Tenders Registered", count: response.tt },
          { title: "Total Active Tenders", count: response.at },
          { title: "Total Closed Tenders", count: response.ct },
        
        ];
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex min-h-screen">
      <CompanySidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
      <div className="flex-1 flex flex-col">
      <Navbar toggleSidebar={toggleSidebar}/>
        <main className="flex-grow p-6">
          <h2 className="text-2xl font-bold mb-4">NGO Dashboard</h2>
          <p className="mb-6">Welcome to the NGO Dashboard! Manage your company details, tenders, and bids here.</p>

          <DashboardCards  data={dashboardData} />
          <PriceButton />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default CompanyDashboard;
