import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../Navbar';
import Footer from '../Footer';
import CompanySidebar from './CompanySidebar';
import { handleAPIRequest } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyProfile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [userDetails, setUserDetails] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Address: '',
    Country: '',
  });

  const [companyDetails, setCompanyDetails] = useState({
    Name: '',
    RegistrationNo: '',
    Address: '',
    Website: '',
    Picture: null,
    DocumentTag: '', // Added document tag state
  });

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const response = await handleAPIRequest("get", "get-company-user-profile", null, null, token);
        if (response) {
          setUserDetails({
            Name: response.user.contact_person || '',
            Phone: response.user.contact_no || '',
            Email: response.user.email || '',
            Address: response.user.address || '',
            Country: response.user.country || '',
          });

          setCompanyDetails({
            Name: response.company.company_name || '',
            RegistrationNo: response.company.registration_no || '',
            Address: response.company.address || '',
            Website: response.company.website || '',
            Picture: response.documents[0]?.document_url || null, // Accessing the first document's URL
            DocumentTag: response.documents[0]?.document_tag || '', // Accessing the first document's tag
          });
        }
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    if (token) {
      fetchCompanyProfile();
    }
  }, [token]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setCompanyDetails((prev) => ({ ...prev, Picture: files[0] }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    
    // Append user details to form data
    formData.append('contact_person', userDetails.Name);
    formData.append('contact_no', userDetails.Phone);
    formData.append('address', userDetails.Address);
    formData.append('country', userDetails.Country);
    
    // Append company details to form data
    formData.append('company_name', companyDetails.Name);
    formData.append('company_address', companyDetails.Address);
    formData.append('website', companyDetails.Website);
    
    // Only append picture and document tag if a new picture is selected
    if (companyDetails.Picture instanceof File) {
      formData.append('companydp_doc', companyDetails.Picture);
      formData.append('companydp_tag', companyDetails.DocumentTag);
    }

    // Call the update API
    try {
      const response = await handleAPIRequest("post", "update-company-user-details", formData, null, token);
      if (response) {
        // Update states with the new values
        toast.success("Profile edited successfully!");
        setUserDetails({
          Name: userDetails.Name,
          Phone: userDetails.Phone,
          Email: userDetails.Email,
          Address: userDetails.Address,
          Country: userDetails.Country,
        });

        setCompanyDetails((prev) => ({
          ...prev,
          Picture: companyDetails.Picture, // Update to the new picture if needed
        }));
      }
    } catch (error) {
      console.error("Error updating company profile:", error);
      toast.error("Error updating profile. Please try again.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
        <CompanySidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
      <div className="flex-1 flex flex-col">
      <Navbar toggleSidebar={toggleSidebar}/>
        <main className="p-4 md:p-6">
          <ToastContainer />
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center md:text-left">NGO Profile</h1>
          <form onSubmit={handleEditSubmit} className="space-y-6">

            {/* User Details */}
            <div className="bg-gray-100 rounded-lg shadow-md p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(userDetails).map(([key, value]) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {key.replace(/([A-Z])/g, ' $1')}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={handleUserChange}
                      className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                      required
                      disabled={key === 'Email'}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Company Details */}
            <div className="bg-gray-100 rounded-lg shadow-md p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">NGO Details</h2>
              
              {/* Profile Picture and Image Upload */}
              <div className="flex items-center justify-center mb-6 md:mb-4">
                <div className="w-24 h-24 md:w-32 md:h-32 flex-shrink-0">
                  {companyDetails.Picture ? (
                    <img
                      src={typeof companyDetails.Picture === 'string' ? companyDetails.Picture : URL.createObjectURL(companyDetails.Picture)}
                      alt="Company Profile"
                      className="w-full h-full rounded-lg object-cover shadow-md"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full border-2 border-gray-300 rounded-lg">
                      <span className="text-gray-500 text-sm">Select Image</span>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="ml-4 text-sm"
                />
              </div>

              {/* Other Company Details */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(companyDetails).map(([key, value]) => (
                  key !== 'Picture' && (
                    <div key={key}>
                      <label className="block text-sm font-medium text-gray-700">
                        {key.replace(/([A-Z])/g, ' $1')}
                      </label>
                      <input
                        type="text"
                        name={key}
                        value={value}
                        onChange={handleCompanyChange}
                        className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                        disabled={key === 'RegistrationNo'}
                      />
                    </div>
                  )
                ))}
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition text-sm md:text-base"
              >
                Save Changes
              </button>
            </div>
          </form>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default CompanyProfile;
