import React from "react";
import Navbar from "./homepage/navbar/Navbar"; 
import Footer from "./homepage/footer/Footer";
import YouTube from "react-youtube";
import waimg from "../assets/images/whatsapp.png";
const Guide = () => {
    // Sample data for guide cards
    const guides = [
        {
            title: "Guide 1",
            description: "This guide covers the basics of using our platform.",
            videoId: "Z1UNwtNQipA", // Replace with actual YouTube video IDs
        },
        {
            title: "Guide 2",
            description: "This guide shows advanced features and tips.",
            videoId: "t9B7LenXZs8",
        },
        {
            title: "Guide 3",
            description: "This guide shows advanced features and tips.",
            videoId: "Qd42s1PqYJg",
        },
    ];

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />

            <div className="flex-1 p-4">
                <h2 className="text-2xl font-bold mb-6">User Guides</h2>

                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {guides.map((guide, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-md p-4">
                            <h3 className="text-xl font-semibold mb-2">{guide.title}</h3>
                            <p className="text-gray-700 mb-4">{guide.description}</p>
                            
                            {/* Embedded YouTube video */}
                            <YouTube videoId={guide.videoId} opts={{ width: "100%", height: "200px" }} />
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
             {/* WhatsApp Fixed Floating Button */}
      <a
        href="https://wa.me/+923121111002"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>

        </div>
    );
};

export default Guide;
