import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../Navbar';
import Footer from '../Footer';
import SupplierSidebar from './supplierSidebar';
import { handleAPIRequest } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SupplierProfile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [userDetails, setUserDetails] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Address: '',
    Country: '',
  });

  const [SupplierDetails, setSupplierDetails] = useState({
    Name: '',
    RegistrationNo: '',
    Address: '',
    Country: '',
    Picture: null,
    DocumentTag: '',
    SupplierCategory: '', // Added SupplierCategory state
  });

  const token = useSelector((state) => state.auth.token);

  const supplierCategoryOptions = [
    'Agricultural Inputs',
    'Auditing and Accounting Firms',
    'Awareness Campaigns',
    'Cash Transfer Services',
    'Construction and Renovation',
    'Construction Materials',
    'Consulting Services',
    'Education Materials',
    'Event and Meeting Supplies',
    'Event Management',
    'Food Service and Catering',
    'Food Supplies',
    'General Order Supplier',
    'Heavy Machinery Rentals',
    'Humanitarian Assistance',
    'IT and Communication Equipment',
    'Insurance Providers',
    'Laboratories and Diagnostic Services',
    'Maintenance Services',
    'Medical Contractors',
    'Medical Supplies',
    'Monitoring and Evaluation',
    'Non-Food Items (NFIs)',
    'Other Services',
    'Packaging Materials',
    'Power Supply',
    'Promotional Materials',
    'Renewable Energy Installation',
    'Security Services',
    'Training Services',
    'Transport and Logistics Services',
    'Transport Equipment Rentals',
    'Water Supply and Sanitation',
  ];

  useEffect(() => {
    const fetchSupplierProfile = async () => {
      try {
        const response = await handleAPIRequest("get", "get-supplier-user-profile", null, null, token);
        if (response) {
          setUserDetails({
            Name: response.user.contact_person || '',
            Phone: response.user.contact_no || '',
            Email: response.user.email || '',
            Address: response.user.address || '',
            Country: response.user.country || '',
          });

          setSupplierDetails({
            Name: response.supplier.supplier_name || '',
            RegistrationNo: response.supplier.registration_no || '',
            Address: response.supplier.address || '',
            Country: response.supplier.country || '',
            Picture: response.documents[0]?.document_url || null,
            DocumentTag: response.documents[0]?.document_tag || '',
            SupplierCategory: response.supplier.category || '', // Assuming `category` is the API field
          });
        }
      } catch (error) {
        console.error("Error fetching Supplier profile:", error);
      }
    };

    if (token) {
      fetchSupplierProfile();
    }
  }, [token]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    setSupplierDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setSupplierDetails((prev) => ({ ...prev, Picture: files[0] }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('contact_person', userDetails.Name);
    formData.append('contact_no', userDetails.Phone);
    formData.append('address', userDetails.Address);
    formData.append('country', userDetails.Country);
    formData.append('email', userDetails.Email);
    formData.append('Supplier_name', SupplierDetails.Name);
    formData.append('Supplier_address', SupplierDetails.Address);
    formData.append('supplier_country', SupplierDetails.Country);
    formData.append('supplier_category', SupplierDetails.SupplierCategory);

    if (SupplierDetails.Picture instanceof File) {
      formData.append('supplierdp_doc', SupplierDetails.Picture);
      formData.append('supplierdp_tag', SupplierDetails.DocumentTag);
    }

    try {
      const response = await handleAPIRequest("post", "update-supplier-user-details", formData, null, token);
      if (response) {
        toast.success("Profile edited successfully!");
      }
    } catch (error) {
      console.error("Error updating Supplier profile:", error);
      toast.error("Error updating profile. Please try again.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
          <SupplierSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 flex flex-col">
      <Navbar toggleSidebar={toggleSidebar} />
        <main className="p-4 md:p-6">
          <ToastContainer />
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center md:text-left">Supplier Profile</h1>
          <form onSubmit={handleEditSubmit} className="space-y-6">

            {/* User Details */}
            <div className="bg-gray-100 rounded-lg shadow-md p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(userDetails).map(([key, value]) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {key.replace(/([A-Z])/g, ' $1')}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={handleUserChange}
                      className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                      required
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Supplier Details */}
            <div className="bg-gray-100 rounded-lg shadow-md p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">Supplier Details</h2>

              {/* Profile Picture */}
              <div className="flex items-center justify-center mb-6 md:mb-4">
                <div className="w-24 h-24 md:w-32 md:h-32 flex-shrink-0">
                  {SupplierDetails.Picture ? (
                    <img
                      src={typeof SupplierDetails.Picture === 'string' ? SupplierDetails.Picture : URL.createObjectURL(SupplierDetails.Picture)}
                      alt="Supplier Profile"
                      className="w-full h-full rounded-lg object-cover shadow-md"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full border-2 border-gray-300 rounded-lg">
                      <span className="text-gray-500 text-sm">Select Image</span>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="ml-4 text-sm"
                />
              </div>

              {/* Other Supplier Details */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(SupplierDetails).map(([key, value]) => (
                  key !== 'Picture' && (
                    <div key={key}>
                      <label className="block text-sm font-medium text-gray-700">
                        {key.replace(/([A-Z])/g, ' $1')}
                      </label>
                      {key === 'SupplierCategory' ? (
                        <select
                          name={key}
                          value={value}
                          onChange={handleSupplierChange}
                          className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                        >
                          <option value="">Select Category</option>
                          {supplierCategoryOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name={key}
                          value={value}
                          onChange={handleSupplierChange}
                          className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                          disabled={key === 'RegistrationNo'}
                        />
                      )}
                    </div>
                  )
                ))}
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition text-sm md:text-base"
              >
                Save Changes
              </button>
            </div>
          </form>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SupplierProfile;
