import React, { useEffect, useState } from "react";
import { handleAPIRequest } from "../../../api/api";
import Navbar from "../navbar/Navbar"; // Adjust import based on your project structure
import Footer from "../footer/Footer"; // Adjust import based on your project structure

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await handleAPIRequest("get", "show-blogsP", null, null, null);
        if (response.status) {
          setBlogs(response.data);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  const handleViewBlog = async (id) => {
    try {
      const response = await handleAPIRequest("get", `show-blog/${id}`, null, null, null);
      if (response.status) {
        setSelectedBlog(response.data);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  const closeModal = () => {
    setSelectedBlog(null);
    setShowModal(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-8">Our Blogs</h1>

          {/* Conditionally render blogs or no blogs message */}
          {blogs.length === 0 ? (
            <p className="text-center text-lg text-gray-600">No blogs available at the moment</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
                >
                  <img
                    src={blog.blog_image}
                    alt={blog.blog_title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h2 className="text-xl font-semibold text-gray-800 truncate">
                      {blog.blog_title}
                    </h2>
                    <p className="text-gray-600 mt-2 text-sm line-clamp-3">
                      {blog.blog_description.slice(0, 100)}...
                    </p>
                    <p className="text-gray-500 mt-1 text-sm italic line-clamp-2">
                      {blog.meta_description.slice(0, 50)}...
                    </p>
                  </div>
                  <div className="p-4 border-t text-right">
                    <button
                      className="bg-blue-600 text-white text-sm px-4 py-2 rounded hover:bg-blue-700"
                      onClick={() => handleViewBlog(blog.id)}
                    >
                      View
                    </button>
                    <span className="ml-4 text-gray-500 text-sm">
                      {new Date(blog.created_at).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
      <Footer />

      {/* Modal */}
      {showModal && selectedBlog && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full mx-4 overflow-hidden">
            <div className="p-6 overflow-y-auto max-h-[90vh]">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                {selectedBlog.blog_title}
              </h2>
              <img
                src={selectedBlog.blog_image}
                alt={selectedBlog.blog_title}
                className="w-full h-64 object-cover rounded-md mb-4"
              />
              <div className="text-gray-700 space-y-4 leading-relaxed">
                <div>
                  <h3 className="font-semibold text-lg text-gray-800 mb-2">Description:</h3>
                  <p className="whitespace-pre-line">{selectedBlog.blog_description}</p>
                </div>
                <div>
                  <h3 className="font-semibold text-lg text-gray-800 mb-2">Meta Description:</h3>
                  <p className="whitespace-pre-line">{selectedBlog.meta_description}</p>
                </div>
              </div>
              <p className="text-gray-400 text-sm mt-4">
                Created At: {new Date(selectedBlog.created_at).toLocaleString()}
              </p>
            </div>
            <div className="p-4 border-t text-right">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
