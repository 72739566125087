import React, { useEffect, useState } from "react";
import CompanySidebar from "./CompanySidebar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Pagination, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, Tooltip } from "@mui/material";
import BidDetails from "../BidDetails";
import { useLocation } from "react-router-dom";
const CompanyBids = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [bids, setBids] = useState([]);
  const [filteredBids, setFilteredBids] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const token = useSelector((state) => state.auth.token);
  const itemsPerPage = 10;
  const [selectedBidId, setSelectedBidId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location.state?.tenderTitle) {
      setSearchQuery(location.state.tenderTitle);
    }
  }, [location.state]);
  const handleViewDetails = (bidId) => {
    setSelectedBidId(bidId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBidId(null);
  };

  const fetchBids = async () => {
    try {
      const response = await handleAPIRequest("get", "get-company-bids", null, null, token);
      if (response?.status && response?.bids) {
        setBids(response.bids);
        setFilteredBids(response.bids);
      }
    } catch (error) {
      console.error("Failed to fetch bids.");
    }
  };

  useEffect(() => {
    fetchBids();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setFilteredBids(
      bids.filter((bid) =>
        [
          "tender.tender_title",
          "created_at",
          "user.contact_person",
        ].some((field) => {
          const value = field.split(".").reduce((o, i) => o[i], bid);
          return value && value.toString().toLowerCase().includes(query);
        })
      )
    );
  };

  const handleChangePage = (event, value) => setCurrentPage(value);

  const currentItems = filteredBids.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getStatusStyle = (status) => {
    switch (status) {
      case "awarded":
        return "bg-green-500 text-white";
      case "pending":
        return "bg-yellow-500 text-white";
      case "under review":
        return "bg-blue-500 text-white";
      case "rejected":
        return "bg-red-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  const handleStatusClick = (bid) => {
    const currentDate = new Date();
    const tenderEndDate = new Date(bid.tender.tender_end_date);
    if (currentDate < tenderEndDate) {
      toast.error("Tender date is not finished. Please wait for the tender to end.");
    } else {
      setSelectedBidId(bid.id);
      setNewStatus(bid.status); // Set initial status
      setStatusModalOpen(true);
    }
  };

  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
    setSelectedBidId(null);
    setNewStatus("");
  };

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleSaveStatus = async () => {
    const formData = {
      bid_id: selectedBidId,
      status: newStatus,
    };

    try {
      const response = await handleAPIRequest("post", "update-bid-status", formData, null, token);
      if (response?.status) {
        toast.success("Bid status updated successfully.");
        fetchBids(); // Refresh the bid list
        handleStatusModalClose();
      } else {
        toast.error(response?.message || "Failed to update bid status.");
      }
    } catch (error) {
      toast.error("Failed to update bid status.");
    }
  
  };

  return (
    <div className="flex min-h-screen flex-col">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="flex flex-1">
      <CompanySidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
        <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar}/>
          <div className="p-4 flex-1">
            <h2 className="text-2xl font-bold mb-4">Company Bids</h2>

            <div className="flex justify-between mb-4">
              <TextField
                label="Search Bids"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                className="w-full md:w-1/3"
              />
            </div>

            <div className="overflow-auto">
              <table className="w-full bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="py-2 px-4 border">Bid ID</th>
                    <th className="py-2 px-4 border">Tender Name</th>
                    <th className="py-2 px-4 border">Submitted On</th>
                    <th className="py-2 px-4 border">Supplier Name</th>
              
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((bid) => {
                      const currentDate = new Date();
                      const tenderEndDate = new Date(bid.tender.tender_end_date);
                      const isPastEndDate = currentDate >= tenderEndDate;

                      return (
                        <tr key={bid.id} className="text-gray-700">
                          <td className="py-2 px-4 border">{bid.id}</td>
                          <td className="py-2 px-4 border">{bid.tender.tender_title}</td>
                          <td className="py-2 px-4 border">
                            {new Date(bid.created_at).toLocaleDateString()}
                          </td>
                          <td className="py-2 px-4 border">{bid.user.contact_person}</td>
                         
                          <td className="py-2 px-4 border">
                            <Tooltip title={isPastEndDate ? "" : "Tender date is not finished. Please wait."}>
                              <span
                                onClick={() => isPastEndDate && handleStatusClick(bid)}
                                className={`px-2 py-1 rounded-full text-xs cursor-pointer ${isPastEndDate ? "" : "cursor-not-allowed"} ${getStatusStyle(bid.status)}`}
                              >
                                {bid.status}
                              </span>
                            </Tooltip>
                          </td>
                          <td className="py-2 px-4 border">
                            <Tooltip title={isPastEndDate ? "" : "Tender date is not finished. Please wait."}>
                              <span>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => isPastEndDate && handleViewDetails(bid.id)}
                                  disabled={!isPastEndDate}
                                >
                                  View Details
                                </Button>
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="py-2 px-4 border text-center" colSpan="7">
                        No bids found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center mt-4">
              <Pagination
                count={Math.ceil(filteredBids.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </div>
          </div>

          {/* Status Update Modal */}
          <Dialog open={statusModalOpen} onClose={handleStatusModalClose}>
            <DialogTitle>Update Bid Status</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <Select value={newStatus} onChange={handleStatusChange}>
                  <MenuItem value="submitted">Submitted</MenuItem>
                  <MenuItem value="under_review">Under Review</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                  <MenuItem value="awarded">Awarded</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStatusModalClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSaveStatus} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Bid Details Modal */}
          {selectedBidId && (
            <BidDetails
              bidId={selectedBidId}
              open={openModal}
              handleClose={handleCloseModal}
            />
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CompanyBids;
