import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom
import StepUser from './steps/stepUser';
import StepRole from './steps/stepRole';
import StepDetails from './steps/stepDetails';
import StepDocuments from './steps/stepDocuments';
import StepConfirmation from './steps/stepConfirmation';
import signUpImage from '../../assets/images/signup.jpg';

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [documents, setDocuments] = useState([]);
  const [tag, setTag] = useState('');
  const [amount, setAmount] = useState('');
  const updateTag = (newTag) => {
    setTag(newTag); // Update the tag state in the parent
  };
  const updateAmount = (newAmount) => {
    setAmount(newAmount); // Update the tag state in the parent
  };
  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    address: '',
    country: '',
    role: '',
    companyDetails: { companyName: '', regNo: '', address: '', website: '' },
    supplierDetails: { supplierName: '', regNo: '', address: '' },
    donorDetails: { donorName: '', address: '', website: '' },
    documents: []
  });

  const updateDocuments = (newDocuments) => {
    setDocuments(newDocuments);
  };

  const updateUserInfo = (field) => (e) => {
    const value = e.target.value;
    const keys = field.split('.'); // Split field path into an array

    setUserInfo((prevState) => {
      const updatedInfo = { ...prevState };
      let temp = updatedInfo;

      // Traverse the keys and assign the value to the correct nested field
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value; // Set value at the last key
        } else {
          if (!temp[key]) temp[key] = {}; // Create the nested object if it doesn't exist
          temp = temp[key]; // Move deeper into the object
        }
      });
      return updatedInfo; // Return the updated state
    });
  };

  const updateRole = (role) => {
    setUserInfo((prevState) => ({
      ...prevState,
      role: role,
      companyDetails: { companyName: '', regNo: '', address: '', website: '' }, // Reset company details
      supplierDetails: { supplierName: '', supplierCategory:'', regNo: '', address: '',country:'' }, // Reset supplier details
      donorDetails: { donorName: '', address: '', website: '' } // Reset donor details
    }));
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepUser nextStep={nextStep} userInfo={userInfo} updateUserInfo={updateUserInfo} />;
      case 2:
        return <StepRole nextStep={nextStep} prevStep={prevStep} updateRole={updateRole} />;
      case 3:
        return <StepDetails nextStep={nextStep} prevStep={prevStep} userInfo={userInfo} updateUserInfo={updateUserInfo} />;
      case 4:
        return (
          <StepDocuments
          nextStep={nextStep}
          prevStep={prevStep}
          updateDocuments={updateDocuments}
          existingDocument={documents[0]} // Pass existing document
          updateTag={updateTag} // Pass the updateTag function
          updateAmount={updateAmount}
        />
        );
      case 5:
        return <StepConfirmation prevStep={prevStep} userInfo={userInfo} documents={documents} tag={tag} amount={amount} />;
      default:
        return null;
    }
  };

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: `url(${signUpImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="w-full max-w-lg mx-auto">
        {/* Heading */}
        <h2 className="text-2xl font-bold text-center mb-4">Signup with your account</h2>

        {/* Step Progress Bar */}
        <div className="flex justify-between items-center mb-8">
          {[1, 2, 3, 4, 5].map((step) => (
            <div
              key={step}
              className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
                currentStep >= step ? 'bg-green-500 text-white' : 'border-gray-300 text-gray-600'
              }`}
            >
              {step}
            </div>
          ))}
        </div>

        {/* Render the current step */}
        <div className="bg-white shadow-lg rounded-lg p-6">{renderStep()}</div>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          <Link to="/" className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400">
            Go Back to Home
          </Link>
          <Link to="/login" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
            Already Signed Up? Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
