import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from '../components/homepage/Homepage';
import Login from '../components/login/Login'; 
import AboutUs from '../components/homepage/AboutUs'; 
import Signup from '../components/signup/Signup'; 
import NotFound from '../components/NotFound/NotFound'
import SuperAdminDashboard from '../components/dashboard/superAdmin/superAdminDashboard';
import SuperAdminProfileDashboard from '../components/dashboard/superAdmin/superAdminProfile';
import SuperAdminPeople from '../components/dashboard/superAdmin/superAdminPeople';
import SuperAdminTender from '../components/dashboard/superAdmin/superAdminTenders';
import CompanyDashboard from '../components/dashboard/company/companyDashboard'; 
import CompanyStaffDashboard from '../components/dashboard/company/companyStaff'; 
import CompanyProfileDashboard from '../components/dashboard/company/companyProfile'; 
import CompanyTendersDashboard from '../components/dashboard/company/companyTender'; 
import DonorDashboard from '../components/dashboard/donor/donorDashboard'; 
import SupplierDashboard from '../components/dashboard/supplier/supplierDashboard'; 
import SupplierPayments from '../components/dashboard/supplier/supplierPayments'; 
import SingleTender from '../components/homepage/SingleTender';

import ProtectedRoute from './ProtectedRoute';
import RedirectRoute from './RedirectRoute';
import CompanyPayments from '../components/dashboard/company/companyPayments';
import SuperAdminPayment from '../components/dashboard/superAdmin/superAdminPayments';
import Guide from '../components/guide';
import SupplierProfile from '../components/dashboard/supplier/supplierProfile';
import SupplierBids from '../components/dashboard/supplier/SupplierBidList';
import CompanyBids from '../components/dashboard/company/companyBidList';
import SuperAdminBids from '../components/dashboard/superAdmin/superAdminBids';
import DonorBids from '../components/dashboard/donor/donorBidList';
import DonorPeople from '../components/dashboard/donor/donorPeopleList';
import DonorPayments from '../components/dashboard/donor/donorPayments';
import DonorTender from '../components/dashboard/donor/donorTenders';
import Pricing from '../components/homepage/Pricing/Pricing';
import BankDetails from '../components/dashboard/superAdmin/bankDetails';
import BlogDetails from '../components/dashboard/superAdmin/blogDetails';
import Blog from '../components/homepage/blogs/Blog';
import CompanySuppliers from '../components/dashboard/company/companySupplierList';
import DonorNgo from '../components/dashboard/donor/donorNgo';
const AppRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/single-tender" element={<SingleTender />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/pricing" element={<Pricing />} />
        {/* Redirect authenticated users away from login */}
        
        <Route path="/login" element={<RedirectRoute element={<Login />} />} />

        <Route path="/signup" element={<Signup />} />

        {/* Protected routes for different dashboards */}
        
        <Route path="/dashboard/admin" element={<ProtectedRoute element={SuperAdminDashboard} />} />
        <Route path="/dashboard/admin/profile" element={<ProtectedRoute element={SuperAdminProfileDashboard} />} />
        <Route path="/dashboard/admin/people" element={<ProtectedRoute element={SuperAdminPeople} />} />
        <Route path="/dashboard/admin/bank-details" element={<ProtectedRoute element={BankDetails} />} />
        <Route path="/dashboard/admin/blog-details" element={<ProtectedRoute element={BlogDetails} />} />
        <Route path="/dashboard/admin/tenders" element={<ProtectedRoute element={SuperAdminTender} />} />
        <Route path="/dashboard/admin/payments" element={<ProtectedRoute element={SuperAdminPayment} />} />
        <Route path="/dashboard/admin/bidding" element={<ProtectedRoute element={SuperAdminBids} />} />

        <Route path="/dashboard/company" element={<ProtectedRoute element={CompanyDashboard} />} />
        <Route path="/dashboard/company/profile" element={<ProtectedRoute element={CompanyProfileDashboard} />} />
        <Route path="/dashboard/company/tenders" element={<ProtectedRoute element={CompanyTendersDashboard} />} />
        <Route path="/dashboard/company/users" element={<ProtectedRoute element={CompanyStaffDashboard} />} />
        <Route path="/dashboard/company/suppliers" element={<ProtectedRoute element={CompanySuppliers} />} />
       <Route path="/dashboard/company/payments" element={<ProtectedRoute element={CompanyPayments} />} />
        <Route path="/dashboard/company/bidding" element={<ProtectedRoute element={CompanyBids} />} />

        <Route path="/dashboard/donor" element={<ProtectedRoute element={DonorDashboard} />} />
        <Route path="/dashboard/donor/bidding" element={<ProtectedRoute element={DonorBids} />} />
        <Route path="/dashboard/donor/suppliers" element={<ProtectedRoute element={DonorPeople} />} />
        <Route path="/dashboard/donor/ngos" element={<ProtectedRoute element={DonorNgo} />} />
        <Route path="/dashboard/donor/tenders" element={<ProtectedRoute element={DonorTender} />} />
        <Route path="/dashboard/donor/payments" element={<ProtectedRoute element={DonorPayments} />} />

        <Route path="/dashboard/supplier" element={<ProtectedRoute element={SupplierDashboard} />} />
        <Route path="/dashboard/supplier/payments" element={<ProtectedRoute element={SupplierPayments} />} />
        <Route path="/dashboard/supplier/profile" element={<ProtectedRoute element={SupplierProfile} />} />
        <Route path="/dashboard/supplier/bidding" element={<ProtectedRoute element={SupplierBids} />} />
      </Routes>
    </Router>
  );
};

export default AppRoute;
