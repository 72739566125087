import React, { useState, useEffect } from "react";
import Navbar from "../Navbar"; // Adjust the import path
import Sidebar from "./superAdminSidebar"; // Adjust the import path
import Footer from "../Footer"; // Adjust the import path
import { handleAPIRequest } from "../../../api/api"; // Adjust the import path
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify"; // Ensure you have react-toastify installed and imported
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState([]); // Ensure it's always an array
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await handleAPIRequest(
          "get",
          "bank-details",
          null,
          null,
          token
        );
        setBankDetails(response.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching bank details:", error);
        toast.error("Failed to fetch bank details.");
      }
    };

    fetchBankDetails();
  }, [token]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleShowModal = (data = null) => {
    setCurrentData(data);
    setIsEdit(!!data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentData(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    const formData = {
      bank_name: currentData?.bank_name,
      account_title: currentData?.account_title,
      account_number: currentData?.account_number,
      iban: currentData?.iban,
      status: currentData?.status,
    };
    console.log(formData, "fd");
    try {
      const response = await handleAPIRequest(
        "post",
        isEdit ? `edit-bank-details/${currentData.id}` : "bank-details",
        formData,
        null,
        token
      );
      toast.success(
        isEdit
          ? "Bank detail updated successfully!"
          : "Bank detail added successfully!"
      );
      if (isEdit) {
        setBankDetails((prev) =>
          Array.isArray(prev)
            ? prev.map((item) =>
                item.id === currentData.id ? response.data : item
              )
            : [response.data]
        );
      } else {
        setBankDetails((prev) =>
          Array.isArray(prev) ? [...prev, response.data] : [response.data]
        );
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error saving bank details:", error);
      toast.error("Failed to save bank details.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await handleAPIRequest("delete", `bank-details/${id}`, null, null, token);
      toast.success("Bank detail deleted successfully!");
      setBankDetails((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting bank detail:", error);
      toast.error("Failed to delete bank detail.");
    }
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-grow p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-700">Bank Details</h2>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={() => handleShowModal()}
            >
              Add Bank Detail
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Bank Name
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Account Title
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Account Number
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    IBAN
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Status
                  </th>
                  <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {bankDetails.length > 0 ? (
                  bankDetails.map((detail) => (
                    <tr key={detail.id} className="border-b">
                      <td className="py-3 px-6 text-gray-600">
                        {detail.bank_name}
                      </td>
                      <td className="py-3 px-6 text-gray-600">
                        {detail.account_title}
                      </td>
                      <td className="py-3 px-6 text-gray-600">
                        {detail.account_number}
                      </td>
                      <td className="py-3 px-6 text-gray-600">{detail.iban}</td>
                      <td className="py-3 px-6 text-gray-600">
                        <span
                          className={`px-3 py-1 rounded-full text-white ${
                            detail.status === "active"
                              ? "bg-green-500"
                              : "bg-red-500"
                          }`}
                        >
                          {detail.status}
                        </span>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <button
                          className="bg-yellow-500 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-600"
                          onClick={() => handleShowModal(detail)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                          onClick={() => handleDelete(detail.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="py-3 px-6 text-center text-gray-500"
                    >
                      No bank details added.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
        <ToastContainer />
        <Footer />
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-11/12 max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">
              {isEdit ? "Edit Bank Detail" : "Add Bank Detail"}
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.bank_name || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      bank_name: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Account Title
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.account_title || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      account_title: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Account Number
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.account_number || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      account_number: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  IBAN
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.iban || ""}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, iban: e.target.value })
                  }
                />
              </div>
            
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Status
                  </label>
                  <select
                    value={currentData?.status || ""}
                    onChange={(e) => setCurrentData({ ...currentData, status: e.target.value })}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
             
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankDetails;
