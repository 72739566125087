import React , {useState , useEffect} from 'react';
import Navbar from '../Navbar'; // Adjust the import path as needed
import SuperAdminSidebar from './superAdminSidebar';
import Footer from '../Footer'; // Adjust the import path as needed
import { handleAPIRequest } from '../../../api/api';
import DashboardCards from '../dashboardCards/dashboardCards';
import PriceButton from '../priceButtons';
import { useSelector } from "react-redux";
const SuperAdminDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleAPIRequest("get", "count-dataA", null,null,token);
        const data = [
          { title: "Total Tenders Registered", count: response.tt },
          { title: "Total Active Tenders", count: response.at },
          { title: "Total Suppliers Registered", count: response.su },
          { title: "Total NGOs Registered", count: response.nu },
          { title: "Total Closed Tenders", count: response.ct },
          { title: "Total Revenue  Generated", count: response.tp },
          { title: "Total Users", count: response.tu },
        ];
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <SuperAdminSidebar  isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar}/>
        <main className="flex-grow p-6">
          <h2 className="text-2xl font-bold mb-4">Admin Dashboard</h2>
          <p className="mb-6">Welcome to the Admin Dashboard! Manage your admin details, tenders, and bids here.</p>
          <DashboardCards  data={dashboardData} />
        <PriceButton />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
