import React from 'react';

const StepDetails = ({ nextStep, prevStep, userInfo, updateUserInfo }) => {
  const { role, companyDetails, supplierDetails, donorDetails } = userInfo;

  const supplierCategories = [
    'Agricultural Inputs',
    'Auditing and Accounting Firms',
    'Awareness Campaigns',
    'Cash Transfer Services',
    'Construction and Renovation',
    'Construction Materials',
    'Consulting Services',
    'Education Materials',
    'Event and Meeting Supplies',
    'Event Management',
    'Food Service and Catering',
    'Food Supplies',
    'General Order Supplier',
    'Heavy Machinery Rentals',
    'Humanitarian Assistance',
    'IT and Communication Equipment',
    'Insurance Providers',
    'Laboratories and Diagnostic Services',
    'Maintenance Services',
    'Medical Contractors',
    'Medical Supplies',
    'Monitoring and Evaluation',
    'Non-Food Items (NFIs)',
    'Other Services',
    'Packaging Materials',
    'Power Supply',
    'Promotional Materials',
    'Renewable Energy Installation',
    'Security Services',
    'Training Services',
    'Transport and Logistics Services',
    'Transport Equipment Rentals',
    'Water Supply and Sanitation',
  ];

  // Check if all required fields are filled for the selected role
  const areFieldsFilled = () => {
    switch (role) {
      case 'company':
        return (
          companyDetails.companyName &&
          companyDetails.regNo &&
          companyDetails.address &&
          companyDetails.website
        );
      case 'supplier':
        return (
          supplierDetails.supplierName &&
          supplierDetails.supplierCategory &&
          supplierDetails.regNo &&
          supplierDetails.address &&
          supplierDetails.country
        );
      case 'donor':
        return (
          donorDetails.donorName &&
          donorDetails.address &&
          donorDetails.website
        );
      default:
        return false; // No role selected
    }
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Role Details</h3>

      {role === 'company' && (
        <div>
          <input
            type="text"
            placeholder="Company Name"
            value={companyDetails.companyName}
            onChange={updateUserInfo('companyDetails.companyName')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Registration Number"
            value={companyDetails.regNo}
            onChange={updateUserInfo('companyDetails.regNo')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Company Address"
            value={companyDetails.address}
            onChange={updateUserInfo('companyDetails.address')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Website URL"
            value={companyDetails.website}
            onChange={updateUserInfo('companyDetails.website')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
        </div>
      )}

      {role === 'supplier' && (
        <div>
          <input
            type="text"
            placeholder="Supplier Name"
            value={supplierDetails.supplierName}
            onChange={updateUserInfo('supplierDetails.supplierName')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <select
            value={supplierDetails.supplierCategory}
            onChange={updateUserInfo('supplierDetails.supplierCategory')}
            className="mb-4 p-2 border rounded w-full"
            required
          >
            <option value="" disabled>
              Select Supplier Category
            </option>
            {supplierCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Registration Number"
            value={supplierDetails.regNo}
            onChange={updateUserInfo('supplierDetails.regNo')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Supplier Address"
            value={supplierDetails.address}
            onChange={updateUserInfo('supplierDetails.address')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Supplier Country"
            value={supplierDetails.country}
            onChange={updateUserInfo('supplierDetails.country')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
        </div>
      )}

      {role === 'donor' && (
        <div>
          <input
            type="text"
            placeholder="Donor Name"
            value={donorDetails.donorName}
            onChange={updateUserInfo('donorDetails.donorName')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Donor Address"
            value={donorDetails.address}
            onChange={updateUserInfo('donorDetails.address')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
          <input
            type="text"
            placeholder="Website URL"
            value={donorDetails.website}
            onChange={updateUserInfo('donorDetails.website')}
            className="mb-4 p-2 border rounded w-full"
            required
          />
        </div>
      )}

      <div className="flex justify-between mt-4">
        <button onClick={prevStep} className="text-green-500">
          Back
        </button>
        <button
          onClick={nextStep}
          className={`bg-green-500 text-white px-4 py-2 rounded ${
            !areFieldsFilled() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={!areFieldsFilled()} // Disable if fields are not filled
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepDetails;
