import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../Navbar';
import Footer from '../Footer';
import SuperAdminSidebar from './superAdminSidebar';
import { handleAPIRequest } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminProfile = () => {

  const [userDetails, setUserDetails] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Address: '',
    Country: '',
  });
  const token = useSelector((state) => state.auth.token);
const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await handleAPIRequest("get", "get-admin-user-profile", null, null, token);
        if (response) {
          setUserDetails({
            Name: response.user.contact_person || '',
            Phone: response.user.contact_no || '',
            Email: response.user.email || '',
            Address: response.user.address || '',
            Country: response.user.country || '',
          });

        }
      } catch (error) {
        console.error("Error fetching admin profile:", error);
      }
    };

    if (token) {
      fetchAdminProfile();
    }
  }, [token]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    
    // Append user details to form data
    formData.append('contact_person', userDetails.Name);
    formData.append('contact_no', userDetails.Phone);
    formData.append('address', userDetails.Address);
    formData.append('country', userDetails.Country);

    // Call the update API
    try {
      const response = await handleAPIRequest("post", "update-admin-user-details", formData, null, token);
      if (response) {
        // Update states with the new values
        toast.success("Profile edited successfully!");
        setUserDetails({
          Name: userDetails.Name,
          Phone: userDetails.Phone,
          Email: userDetails.Email,
          Address: userDetails.Address,
          Country: userDetails.Country,
        });
      }
    } catch (error) {
      console.error("Error updating admin profile:", error);
      toast.error("Error updating profile. Please try again.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <SuperAdminSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
      <div className="flex-1 flex flex-col">
      <Navbar toggleSidebar={toggleSidebar}/>
        <main className="p-4 md:p-6">
          <ToastContainer />
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center md:text-left">Admin Profile</h1>
          <form onSubmit={handleEditSubmit} className="space-y-6">

            {/* User Details */}
            <div className="bg-gray-100 rounded-lg shadow-md p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(userDetails).map(([key, value]) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {key.replace(/([A-Z])/g, ' $1')}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={handleUserChange}
                      className="mt-1 block w-full h-10 border-gray-300 rounded-md p-2 text-sm"
                      required
                      disabled={key === 'Email'}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition text-sm md:text-base"
              >
                Save Changes
              </button>
            </div>
          </form>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AdminProfile;
