import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Navbar from "../Navbar";
import Sidebar from "./donorSidebar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { toast , ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCircleInfo } from "react-icons/fa6";

const DonorNgo = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const token = useSelector((state) => state.auth.token);
  

  // Modal state for Add NGO
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    contact_person: "",
    contact_no: "",
    email: "",
    password: "",
    address: "",
    country: "",
  });
  const [ngoDetails, setNgoDetails] = useState({
    company_name: "",
    registration_number: "",
    company_address: "",
    website: "",
  });

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [affiliationDetails, setAffiliationDetails] = useState(null);
  const renderAffiliationDetails = (affiliation) => {
    const { affiliation_type, details } = affiliation;

    switch (affiliation_type) {
      case "App\\Models\\Company":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Company Details</h3>
            <p>
              <strong>Name:</strong> {details.company_name}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Donor":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Donor Details</h3>
            <p>
              <strong>Name:</strong> {details.donor_name}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={details.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {details.website}
              </a>
            </p>
          </div>
        );

      case "App\\Models\\Supplier":
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Supplier Details</h3>
            <p>
              <strong>Name:</strong> {details.supplier_name}
            </p>
            <p>
              <strong>Category:</strong> {details.supplier_category}
            </p>
            <p>
              <strong>Registration No:</strong> {details.registration_no}
            </p>
            <p>
              <strong>Address:</strong> {details.address}
            </p>
            <p>
              <strong>Country:</strong> {details.country}
            </p>
          </div>
        );

      default:
        return (
          <div className="mb-4">
            <h3 className="font-semibold text-lg">Affiliation Details</h3>
            <pre>{JSON.stringify(details, null, 2)}</pre>
          </div>
        );
    }
  };
  const fetchAffiliationDetails = async (userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);

    try {
      const response = await handleAPIRequest(
        "post",
        "getADetails",
        formData,
        null,
        token
      );

      if (response && response.affiliations) {
        setAffiliationDetails(response);
        setIsDetailsModalOpen(true);
      } else {
        Swal.fire("Error", "Failed to fetch affiliation details", "error");
      }
    } catch (error) {
      console.error("Error fetching affiliation details:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching affiliation details",
        "error"
      );
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "get-donor-ngos",
        null,
        null,
        token
      );
      if (response && response.status) {
        setUsers(response.users);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to fetch users data",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching users data",
        "error"
      );
    }
  };

  const addNgo = async () => {
    const formData = new FormData();

    // Append user details
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }

    // Append NGO details
    for (let key in ngoDetails) {
      formData.append(key, ngoDetails[key]);
    }

    try {
      const response = await handleAPIRequest(
        "post",
        "create-donor-ngo",
        formData,
        null,
        token
      );

      if (response) {
        toast.success("NGO added successfully");
        setIsAddModalOpen(false); // Close the modal
        fetchUsers(); // Refresh the users list
      } else {
        toast.error("Failed to add NGO");
      }
    } catch (error) {
      console.error("Error adding NGO:", error);
      toast.error("An error occurred while adding NGO");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const filteredUsers = users.filter((user) => {
    const searchString = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchString) ||
      user.email.toLowerCase().includes(searchString) ||
      user.contact_no.toLowerCase().includes(searchString) ||
      user.status.toLowerCase().includes(searchString) ||
      user.roles.some((role) => role.toLowerCase().includes(searchString))
    );
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  return (
    <div className="flex h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
    
      <div className="flex flex-col w-full">
        <Navbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">Donor NGOs</h2>
          
          {/* Add NGO Button */}
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="bg-green-500 text-white py-2 px-4 rounded mb-4"
          >
            Add NGO
          </button>

          {/* Add NGO Modal */}
          {isAddModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/2">
      <h2 className="text-xl font-semibold mb-4">Add NGO</h2>

      <div className="modal-content max-h-[70vh] overflow-y-auto">
        <h3 className="font-semibold mb-2">User Details</h3>
        <input
          type="text"
          placeholder="Contact Person"
          value={userDetails.contact_person}
          onChange={(e) => setUserDetails({ ...userDetails, contact_person: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Contact No"
          value={userDetails.contact_no}
          onChange={(e) => setUserDetails({ ...userDetails, contact_no: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="email"
          placeholder="Email"
          value={userDetails.email}
          onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={userDetails.password}
          onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Address"
          value={userDetails.address}
          onChange={(e) => setUserDetails({ ...userDetails, address: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Country"
          value={userDetails.country}
          onChange={(e) => setUserDetails({ ...userDetails, country: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />

        <h3 className="font-semibold mb-2">NGO Details</h3>
        <input
          type="text"
          placeholder="Company Name"
          value={ngoDetails.company_name}
          onChange={(e) => setNgoDetails({ ...ngoDetails, company_name: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Registration Number"
          value={ngoDetails.registration_number}
          onChange={(e) => setNgoDetails({ ...ngoDetails, registration_number: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Company Address"
          value={ngoDetails.company_address}
          onChange={(e) => setNgoDetails({ ...ngoDetails, company_address: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          placeholder="Website"
          value={ngoDetails.website}
          onChange={(e) => setNgoDetails({ ...ngoDetails, website: e.target.value })}
          className="w-full p-2 mb-2 border rounded"
        />
      </div>

      <div className="flex justify-end">
        <button
          onClick={() => setIsAddModalOpen(false)}
          className="px-4 py-2 bg-gray-300 rounded mr-2"
        >
          Close
        </button>
        <button
          onClick={addNgo}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Add NGO
        </button>
      </div>
    </div>
  </div>
)}


          {/* Table and other content */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">ID</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Contact No</th>
                  <th className="py-2 px-4 border-b">Role</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr
                    key={user.id}
                    className={
                      user.status === "suspended" ? "bg-yellow-100" : ""
                    }
                  >
                    <td className="py-2 px-4 border-b">{user.id}</td>
                    <td className="py-2 px-4 border-b">{user.name}</td>
                    <td className="py-2 px-4 border-b">{user.email}</td>
                    <td className="py-2 px-4 border-b">{user.contact_no}</td>
                    <td className="py-2 px-4 border-b">
                                NGO
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button>
                        {user.status === "active" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                            Active
                          </span>
                        ) : user.status === "suspended" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-black bg-yellow-400">
                            Suspended
                          </span>
                        ) : (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                            Inactive
                          </span>
                        )}
                      </button>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => fetchAffiliationDetails(user.id)}
                        className="text-blue-600 hover:text-blue-800 transition duration-200 text-lg"
                      >
                        <FaCircleInfo />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isDetailsModalOpen && affiliationDetails && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-full max-w-[90%] md:w-1/2">
              <h2 className="text-xl font-semibold mb-4">
                Affiliation Details
              </h2>
              {affiliationDetails.affiliations.map((affiliation, index) => (
                <div key={index}>{renderAffiliationDetails(affiliation)}</div>
              ))}
              <div className="flex justify-end">
                <button
                  onClick={() => setIsDetailsModalOpen(false)}
                  className="px-4 py-2 bg-gray-300 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      <ToastContainer />
    </div>
  );
};

export default DonorNgo;
