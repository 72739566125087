// src/components/dashboard/Donor/DonorSidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUsers, FaFileContract, FaGavel, FaMoneyBill, FaHourglassStart} from 'react-icons/fa';
import { MdClose } from "react-icons/md";

const DonorSidebar = ({ isOpen, toggleSidebar }) => {
  const donorLinks = [
    { path: "/", label: "Home", icon: <FaHome /> },
    { path: "/dashboard/donor", label: "Dashboard", icon: <FaHourglassStart /> },
    { path: "/dashboard/donor/suppliers", label: "Suppliers", icon: <FaUsers /> },
    { path: "/dashboard/donor/ngos", label: "NGOs", icon: <FaUsers /> },
    { path: "/dashboard/donor/tenders", label: "Tenders", icon: <FaFileContract /> },
    { path: "/dashboard/donor/bidding", label: "Bidding", icon: <FaGavel /> },
    { path: "/dashboard/donor/payments", label: "Payments", icon: <FaMoneyBill /> },
  ];

  return (
    <div
    className={`p-4 fixed lg:static top-0 left-0 w-64 bg-gray-800 text-white transition-transform duration-300 lg:translate-x-0 z-50 ${
      isOpen ? "translate-x-0 min-h-screen" : "-translate-x-full"
    }`}
  >
      {/* Close button for mobile */}
      <button
        className="lg:hidden text-white mb-4"
        onClick={toggleSidebar}
      >
        <MdClose size={24} />
      </button>
      <h2 className="text-xl font-bold mb-6">Donor Menu</h2>
      <nav className="space-y-4">
        {donorLinks.map(({ path, label, icon }) => (
          <Link
            key={path}
            to={path}
            className="flex items-center space-x-3 hover:text-blue-400 transition duration-200"
            onClick={toggleSidebar} // Close sidebar on mobile when link is clicked
          >
            {icon}
            <span>{label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default DonorSidebar;
