import React, { useState } from "react";
import { handleAPIRequest } from "../../api/api";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { MdInsertDriveFile } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import "./bidModal.css"; // Import the CSS file

const BidModal = ({ open, onClose, tender }) => {
  const [mainDocument, setMainDocument] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const token = useSelector((state) => state.auth.token);
  if (!tender) return null;

  const { bid_method, bid_notes, required_documents, id: tender_id } = tender;

  const handleFileUpload = (index, file) => {
    if (file) {
      const newUploadedFiles = { ...uploadedFiles, [index]: file };
      setUploadedFiles(newUploadedFiles);
    }
  };

  const handleMainDocumentUpload = (file) => {
    if (file) setMainDocument(file);
  };

  const handleBidSubmit = async () => {
    if (!mainDocument || required_documents.some((_, i) => !uploadedFiles[i])) {
      setError("Please upload the bid document and complete all required fields.");
      return;
    }

    setLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("tender_id", tender_id);
    formData.append("main_doc", mainDocument);

    required_documents.forEach((doc, index) => {
      if (uploadedFiles[index]) {
        formData.append("documents[]", uploadedFiles[index]);
      }
    });

    try {
      const response = await handleAPIRequest(
        "post",
        "bid-for-tender",
        formData,
        null,
        token
      );

      if (response) {
        toast.success("Bid submitted successfully!");
        onClose();
      } else {
        toast.error(response.message || "Failed to submit bid.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (!bid_method || (!required_documents && bid_method === "Online")) {
      return <p style={{ color: "red" }}>Details not specified, can't bid.</p>;
    }

    if (bid_method === "Manual") {
      return (
        <div>
          <p className="font-semibold">
            Note: This tender requires manual bidding. Please follow the provided instructions.
          </p>
          <p>{bid_notes?.trim() ? bid_notes : "No instructions provided. Contact support."}</p>
        </div>
      );
    }

    if (bid_method === "Online") {
      return (
        <div>
          {/*  Bid Document Upload Section */}
          <div className="input-section">
            <h6 className="label">Bid Document</h6>
            <label className="upload-button">
              <input
                type="file"
                onChange={(e) => handleMainDocumentUpload(e.target.files[0])}
                className="file-input"
                required
              />
              Upload
            </label>
            {mainDocument && (
              <div className="preview-container">
                {mainDocument.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(mainDocument)}
                    alt="Bid Document Preview"
                    className="preview-image"
                  />
                ) : (
                  <div className="icon-container mt-10" style={{ marginLeft:"340px" }}>
                    <MdInsertDriveFile className="fallback-icon" />
                    <p className="file-name">{mainDocument.name}</p>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Required Documents Section */}
          <div className="input-section">
            <h6 className="label">Required Documents:</h6>
            {required_documents.map((doc, index) => (
              <div key={index} className="file-upload">
                <span className="doc-name">{doc}</span>
                <label className="upload-button">
                  <input
                    type="file"
                    onChange={(e) => handleFileUpload(index, e.target.files[0])}
                    className="file-input"
                    required
                  />
                  Upload
                </label>
                {uploadedFiles[index] && (
                  <div className="preview-container">
                    {uploadedFiles[index]?.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(uploadedFiles[index])}
                        alt={`Preview ${doc}`}
                        className="preview-image"
                      />
                    ) : (
                      <div className="icon-container">
                        <MdInsertDriveFile className="fallback-icon" />
                        <p className="file-name">{uploadedFiles[index]?.name}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}

          <button
            onClick={handleBidSubmit}
            disabled={loading}
            className="submit-button"
          >
            {loading ? <div className="loader"></div> : "Submit Bid"}
          </button>
        </div>
      );
    }
  };

  return (
    open && (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h2 className="modal-title">Submit Your Bid</h2>
          {renderContent()}
        </div>
        <ToastContainer />
      </div>
    )
  );
};

export default BidModal;
